import React,{useState, useRef} from 'react'
import user1 from '../../assets/images/user1.png'
import camera_icon from '../../assets/icons/camera.svg'
import { useDispatch, useSelector } from 'react-redux'
import { Logout } from '../../redux/Auth/auth'
import { useNavigate } from 'react-router-dom'
import Modal from '../../components/Modals/modal'
import eye from '../../assets/icons/eye.svg'
import eye_off from '../../assets/icons/eye_off.svg'
import { Formik, Form } from 'formik'
import { ChangePasswordValidator, ChangePinValidator } from '../../validationSchema/validator'
import { updateUserPassword, updateUserPin } from '../../redux/Auth/authActions'

const SettingsPage = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { userInfo, loading} = useSelector((state) => state.auth);

    const [showPasswordModal, setShowPasswordModal] = useState(false)
    const [showPinModal, setShowPinModal] = useState(false)
    const [showLogoutModal, setLogoutModal] = useState(false)

    const PasswordModal = () => {
        setShowPasswordModal(!showPasswordModal)
    }

    const PinModal = () => {
        setShowPinModal(!showPinModal)
    }

    const LogoutModal = () =>{
        setLogoutModal(!showLogoutModal)
    }

    const [passwordShown, setPasswordShown] = useState(false)
    const [confirmpasswordShown, setconfirmPasswordShown] = useState(false);

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true)
    }

    const toggleConfirmPasswordVisiblity = () => {
        setconfirmPasswordShown(confirmpasswordShown ? false : true);
      };

      const fileInputRef = useRef();
  
  
      const changedHandler = () => {
          let files = fileInputRef.current.files[0];
          console.log(files)
         
      }

      const handleSubmit = (values) => {
        console.log(values)
        dispatch(updateUserPassword({
            oldpassword: values.oldpassword,
            password: values.password,
        }))
        setTimeout(()=>{
            navigate('/')
        },1000)
    }

    const handleChangePin = (values) =>{
        console.log(values)
        dispatch(updateUserPin({
            oldpin: values.oldpin,
            pin: values.pin,
        }))
    }


    const handleLogout = () => {
        dispatch(Logout())
        navigate('/')
    }

       

    return ( 
        <>
        {/* change password */}
          <Modal show={showPasswordModal} title="Change Password" showModal={PasswordModal} >
                <div className='mt-2'>
                  <p className='text-[#898CA6] font-Graphix500 text-sm text-center'>Set Your Password</p>
                </div>
                <div className='max-w-sm mx-auto'>
                     <Formik
                    onSubmit={(values, { setSubmitting }) =>
                    handleSubmit(values, setSubmitting)
                    }
                    validationSchema={ChangePasswordValidator}
                    initialValues={{
                    oldpassword: '',
                    password: '',
                    confirm_password: ''
                    }}
                >
                    {({
                    handleChange,
                    isSubmitting,
                    handleSubmit,
                    handleBlur,
                    values,
                    touched,
                    errors,
                    }) => (
                    <Form className="mt-6" onSubmit={handleSubmit}>
                        <div>
                        <label htmlFor='oldpassword' className="block mb-2 text-[#333F51] text-sm font-medium">
                            Old Password
                        </label>
                        <div className='relative'>
                        <input
                            type='password'
                            name="oldpassword"
                            placeholder="Enter your old password"
                            className={
                            touched.oldpassword && errors.oldpassword
                                ? 'appearance-none w-full placeholder:text-[#8896AB] placeholder:text-sm  text-[#8896AB] text-sm focus:border-error focus:outline-none rounded-lg border border-error bg-white py-4 px-4'
                                : 'appearance-none w-full placeholder:text-[#8896AB] placeholder:text-sm text-[#8896AB] text-sm focus:border-[#D5DAE1] shadow-input  focus:outline-none focus:shadow-input rounded-lg border border-[#D8D8D8] bg-white py-4 px-4'
                            }
                            value={values.oldpassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        </div>
                      
                        {touched.oldpassword && errors.oldpassword ? (
                            <small className="text-[#FF002A] font-[300] text-[10px] pt-[4px]">
                                {touched.oldpassword && errors.oldpassword}
                            </small>
                        ) : null}
                        </div>

                        <div className="mt-5">
                        <label htmlFor='password' className="block mb-2 text-[#333F51] text-sm font-medium">
                            New Password
                        </label>
                        <div className="relative">
                            <input
                            type={passwordShown ? 'text' : 'password'}
                            name="password"
                            placeholder="Enter new password"
                            className={
                                touched.password && errors.password
                                ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#8896AB] text-sm focus:border-error focus:outline-none rounded-lg border border-error bg-white py-4 px-4'
                                : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#8896AB] text-sm focus:border-[#D5DAE1] focus:outline-none shadow-input focus:shadow-input rounded-lg border border-[#D8D8D8] bg-white py-4 px-4'
                            }
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            />
                            <img
                            onClick={togglePasswordVisiblity}
                            src={passwordShown ? eye_off : eye}
                            alt="eye"
                            className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                            />
                        </div>
                        {touched.password && errors.password ? (
                            <small className="text-[#FF002A] font-[300] text-[10px] pt-[4px]">
                                {touched.password && errors.password}
                            </small>
                        ) : null}
                        </div>

                        <div className="mt-5">
                        <label htmlFor='confirm_password' className="block mb-2 text-[#333F51] text-sm font-medium">
                            Confirm Password
                        </label>
                        <div className="relative">
                            <input
                            type={confirmpasswordShown ? 'text' : 'password'}
                            name="confirm_password"
                            placeholder="Confirm your new password"
                            className={
                                touched.confirm_password && errors.confirm_password
                                ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#8896AB] text-sm focus:border-error focus:outline-none rounded-lg border border-error bg-white py-4 px-4'
                                : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#8896AB] text-sm focus:border-[#D5DAE1] focus:outline-none shadow-input focus:shadow-input rounded-lg border border-[#D8D8D8] bg-white py-4 px-4'
                            }
                            value={values.confirm_password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            />
                            <img
                            onClick={toggleConfirmPasswordVisiblity}
                            src={confirmpasswordShown ? eye_off : eye}
                            alt="eye"
                            className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                            />
                        </div>
                        {touched.confirm_password && errors.confirm_password ? (
                            <small className="text-[#FF002A] font-[300] text-[10px] pt-[4px]">
                                {touched.confirm_password && errors.confirm_password}
                            </small>
                        ) : null}
                        {/* <div className='flex justify-end mt-1'>
                            <small className='text-[#06D2DD] text-xs font-Graphix400'>*Make sure the password matches each other</small>
                        </div> */}
                        </div>

                        <div className="mt-6">
                        <button
                            type="submit"
                            disabled={loading}
                            className="bg-[#0041B1] 
                            disabled:bg-[#0041B1] 
                            disabled:opacity-[0.7]
                            text-sm
                            rounded-lg w-full py-4 text-[#F0FDF4] font-medium hover:bg-[#0041B1] hover:opacity-[0.9] transition-all"
                        >
                            Change Password
                        </button>
                        </div>

                        <div onClick={PasswordModal} className='text-center mt-5'>
                            <p className='text-[#8896AB] text-sm font-medium cursor-pointer'>Skip for now 
                             </p>
                        </div>

                    
                    </Form>
                    )}
                     </Formik>
                </div>
            </Modal>
        {/* change pin */}
         <Modal show={showPinModal} title="Change Pin" showModal={PinModal} >
                <div className='mt-2'>
                  <p className='text-[#898CA6] font-Graphix500 text-sm text-center'>Set Your 4 digit Transaction Pin.</p>
                </div>
                <div className='max-w-sm mx-auto'>
                     <Formik
                    onSubmit={(values, { setSubmitting }) =>
                    handleChangePin(values, setSubmitting)
                    }
                    validationSchema={ChangePinValidator}
                    initialValues={{
                    oldpin: '',
                    pin: '',
                    confirm_pin: ''
                    }}
                >
                    {({
                    handleChange,
                    isSubmitting,
                    handleSubmit,
                    handleBlur,
                    values,
                    touched,
                    errors,
                    }) => (
                    <Form className="mt-6" onSubmit={handleSubmit}>
                        <div>
                        <label htmlFor='oldpin' className="block mb-2 text-[#333F51] text-sm font-medium">
                            Old Pin
                        </label>
                        <div className='relative'>
                        <input
                            type='password'
                            name="oldpin"
                            placeholder="Enter your old pin"
                            className={
                            touched.oldpin && errors.oldpin
                                ? 'appearance-none w-full placeholder:text-[#8896AB] placeholder:text-sm  text-[#8896AB] text-sm focus:border-error focus:outline-none rounded-lg border border-error bg-white py-4 px-4'
                                : 'appearance-none w-full placeholder:text-[#8896AB] placeholder:text-sm text-[#8896AB] text-sm focus:border-[#D5DAE1] shadow-input  focus:outline-none focus:shadow-input rounded-lg border border-[#D8D8D8] bg-white py-4 px-4'
                            }
                            value={values.oldpin}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        </div>
                      
                        {touched.oldpin && errors.oldpin ? (
                            <small className="text-[#FF002A] font-[300] text-[10px] pt-[4px]">
                                {touched.oldpin && errors.oldpin}
                            </small>
                        ) : null}
                        </div>

                        <div className="mt-5">
                        <label htmlFor='pin' className="block mb-2 text-[#333F51] text-sm font-medium">
                            New Pin
                        </label>
                        <div className="relative">
                            <input
                            type={passwordShown ? 'text' : 'password'}
                            name="pin"
                            placeholder="Enter new pin"
                            className={
                                touched.pin && errors.pin
                                ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#8896AB] text-sm focus:border-error focus:outline-none rounded-lg border border-error bg-white py-4 px-4'
                                : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#8896AB] text-sm focus:border-[#D5DAE1] focus:outline-none shadow-input focus:shadow-input rounded-lg border border-[#D8D8D8] bg-white py-4 px-4'
                            }
                            value={values.pin}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            />
                            <img
                            onClick={togglePasswordVisiblity}
                            src={passwordShown ? eye_off : eye}
                            alt="eye"
                            className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                            />
                        </div>
                        {touched.pin && errors.pin ? (
                            <small className="text-[#FF002A] font-[300] text-[10px] pt-[4px]">
                                {touched.pin && errors.pin}
                            </small>
                        ) : null}
                        </div>

                        <div className="mt-5">
                        <label htmlFor='confirm_pin' className="block mb-2 text-[#333F51] text-sm font-medium">
                            Confirm Pin
                        </label>
                        <div className="relative">
                            <input
                            type={confirmpasswordShown ? 'text' : 'password'}
                            name="confirm_pin"
                            placeholder="Confirm your pin"
                            className={
                                touched.confirm_pin && errors.confirm_pin
                                ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#8896AB] text-sm focus:border-error focus:outline-none rounded-lg border border-error bg-white py-4 px-4'
                                : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#8896AB] text-sm focus:border-[#D5DAE1] focus:outline-none shadow-input focus:shadow-input rounded-lg border border-[#D8D8D8] bg-white py-4 px-4'
                            }
                            value={values.confirm_pin}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            />
                            <img
                            onClick={toggleConfirmPasswordVisiblity}
                            src={confirmpasswordShown ? eye_off : eye}
                            alt="eye"
                            className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                            />
                        </div>
                        {touched.confirm_pin && errors.confirm_pin ? (
                            <small className="text-[#FF002A] font-[300] text-[10px] pt-[4px]">
                                {touched.confirm_pin && errors.confirm_pin}
                            </small>
                        ) : null}
                            <div className='flex justify-end mt-1'>
                                <small className='text-[#06D2DD] text-xs font-Graphix400'>*Make sure the pin matches each other</small>
                            </div>
                        </div>

                        <div className="mt-6">
                        <button
                            type="submit"
                            disabled={loading}
                            className="bg-[#0041B1] 
                            disabled:bg-[#0041B1] 
                            disabled:opacity-[0.7]
                            text-sm
                            rounded-lg w-full py-4 text-[#F0FDF4] font-medium hover:bg-[#0041B1] hover:opacity-[0.9] transition-all"
                        >
                            Change Pin
                        </button>
                        </div>

                        <div onClick={PinModal} className='text-center mt-5'>
                            <p className='text-[#8896AB] text-sm font-medium cursor-pointer'>Skip for now 
                             </p>
                        </div>

                    
                    </Form>
                    )}
                     </Formik>
                </div>
            </Modal>

            {/* logout modal */}
            <Modal show={showLogoutModal} title="Log Out" showModal={LogoutModal} >
                <div className='mt-2'>
                  <p className='text-[#898CA6] font-Graphix500 text-sm text-center'>Sign off Batchbills</p>
                </div>
                <div className='max-w-sm mx-auto'>
                    <div className='mt-6 flex justify-center'>
                        <p className='text-[#010918] font-Graphix400 text-sm'>Are you sure you want to Logout?</p>
                    </div>
                   <div className="mt-6">
                        <button
                          onClick={handleLogout}
                            type="submit"
                            className="bg-[#0041B1] 
                            disabled:bg-[#0041B1] 
                            disabled:opacity-[0.7]
                            text-sm
                            rounded-lg w-full py-4 text-[#F0FDF4] font-medium hover:bg-[#0041B1] hover:opacity-[0.9] transition-all"
                        >
                            Yes, Proceed
                        </button>
                        </div>

                    <div onClick={LogoutModal} className='text-center mt-5'>
                        <p className='text-[#8896AB] text-sm font-medium cursor-pointer'>Cancel
                         </p>
                    </div>
                </div>
            </Modal>


            <div className='mt-5 md:mt-10 bg-[#FFFFFF] rounded-xl py-6 px-6'>
                <div className='border-[0.98px] border-[#D8D8D8] rounded-[10px] py-8 px-6'>
                    <div className='flex flex-col md:flex-row md:justify-between md:items-center'>
                        <div>
                            <h6 className='font-Lota400 text-[#010918]'>Personal Information</h6>
                            <p className='text-[#B9BDC8] font-Lota300 text-sm'>Individual information about this customer</p>
                        </div>
                        <div>
                            <button
                            type="submit"
                            onClick={LogoutModal}
                            disabled={false}
                            className="bg-white 
                            disabled:bg-[#FF0000] 
                            disabled:opacity-[0.7]
                            text-sm
                            font-Graphix500 mt-4 md:mt-0
                            border border-[#FF0000]
                            flex items-center gap-3
                            rounded-[6px] md:w-full py-3 px-6 text-[#FF0000] 
                            font-medium hover:bg-[#FF0000] hover:text-white hover:opacity-[0.9] transition-all">
                                Log out
                            </button>
                        </div>
                    </div>

                    <div className='flex flex-col md:flex-row gap-10 mt-6'>
                        <div>
                            <div className='relative'>
                              <img src={user1} alt="user" className='w-[180px] h-[180px] rounded-full' />

                                 <label className='absolute cursor-pointer right-28 md:right-6 bottom-1 w-[24px] h-[24px] bg-[#06D2DD] rounded-full flex items-center justify-center'>
                                    <input 
                                    ref={fileInputRef}
                                    type="file"
                                    accept="image/*"  
                                    onChange={() => changedHandler()}
                                    className='hidden'/>
                                    <img src={camera_icon} alt="camera" />
                                </label> 
                            </div>
                        
                        </div>
                        <div>
                            <div>
                            <label htmlFor='firstName' className="block mb-2 text-[#333F51] text-sm font-Lota400">
                                First Name
                            </label>
                                <input
                                    type="text"
                                    name="firstName"
                                    placeholder="Solomon Brady"
                                    disabled
                                    value={`${userInfo ? userInfo.firstName : ''}`}
                                    className=
                                     'appearance-none w-full md:min-w-[500px] font-Graphix400 placeholder:text-[#96A397] placeholder:text-sm text-[#8896AB] text-sm focus:border-[#D5DAE1] focus:outline-none shadow-input focus:shadow-input rounded-lg border border-[#D5DAE1] bg-white py-4 px-4' 
                                />
                            </div>

                            <div className='mt-5'>
                            <label htmlFor='lastName' className="block mb-2 text-[#333F51] text-sm font-Lota400">
                                Last Name
                            </label>
                                <input
                                    type="text"
                                    name="lastName"
                                    placeholder="Solomon Brady"
                                    disabled
                                    value={`${userInfo ? userInfo.lastName : ''}`}
                                    className=
                                     'appearance-none w-full md:min-w-[500px] font-Graphix400 placeholder:text-[#96A397] placeholder:text-sm text-[#8896AB] text-sm focus:border-[#D5DAE1] focus:outline-none shadow-input focus:shadow-input rounded-lg border border-[#D5DAE1] bg-white py-4 px-4' 
                                />
                            </div>

                            <div className='mt-5'>
                            <label htmlFor='email' className="block mb-2  text-[#333F51] text-sm font-Lota400">
                            Email Address
                            </label>
                                <input
                                    type="text"
                                    name="email"
                                    disabled
                                    placeholder="email@email.com"
                                    value={`${userInfo ? userInfo.email : ''}`}
                                    className=
                                     'appearance-none w-full md:min-w-[500px] font-Graphix400 placeholder:text-[#96A397] placeholder:text-sm text-[#8896AB] text-sm focus:border-[#D5DAE1] focus:outline-none shadow-input focus:shadow-input rounded-lg border border-[#D5DAE1] bg-white py-4 px-4' 
                                />
                            </div>

                            <div className='mt-5'>
                            <label htmlFor='phoneNumber' className="block mb-2  text-[#333F51] text-sm font-Lota400">
                            Phone Number
                            </label>
                                <input
                                    type="text"
                                    name="phoneNumber"
                                    disabled
                                    placeholder="07000000000"
                                    value={`${userInfo ? userInfo.phoneNumber : ''}`}
                                    className=
                                     'appearance-none w-full md:min-w-[500px] font-Graphix400 placeholder:text-[#96A397] placeholder:text-sm text-[#8896AB] text-sm focus:border-[#D5DAE1] focus:outline-none shadow-input focus:shadow-input rounded-lg border border-[#D5DAE1] bg-white py-4 px-4' 
                                />
                            </div>

                            <div className='flex justify-center'>
                            <button
                                    type="submit"
                                    disabled={false}
                                    className="bg-[#0041B1] 
                                    disabled:bg-[#0041B1] 
                                    disabled:opacity-[0.7]
                                    text-sm
                                    font-Lota600
                                    mt-6
                                    rounded-[5px] py-4 px-10 text-[#F0FDF4] 
                                    font-medium hover:bg-[#0041B1] hover:opacity-[0.9] transition-all"
                                >   
                                    Save Changes
                            </button>
                            </div>

                        </div>
                    </div>
                </div>

                <div className='border-[0.98px] border-[#D8D8D8] rounded-[10px] py-6 px-6 mt-4'>
                        <div>
                            <h6 className='font-Lota400 text-[#010918]'>Security</h6>
                            <p className='text-[#B9BDC8] font-Lota300 text-sm'>Individual information about this customer</p>

                            <div className='mt-4'>
                            <label htmlFor='email' className="block mb-2 text-[#333F51] text-sm font-Lota400">
                                BVN
                            </label>
                                <input
                                    type="text"
                                    name="email"
                                    placeholder="0000000000000"
                                    className=
                                     'appearance-none w-full md:w-[500px] font-Graphix400 placeholder:text-[#96A397] placeholder:text-sm text-[#8896AB] text-sm focus:border-[#D5DAE1] focus:outline-none shadow-input focus:shadow-input rounded-lg border border-[#D5DAE1] bg-white py-4 px-4' 
                                />
                            </div>

                        </div>


                        <div className='mt-8'>
                            <h6 className='font-Lota400 text-[#010918]'>Change Password</h6>
                            <p className='text-[#B9BDC8] font-Lota300 text-sm'>Individual information about this customer</p>

                            <button
                                  onClick={PasswordModal}
                                    type="submit"
                                    disabled={false}
                                    className="bg-white 
                                    text-sm
                                    font-Graphix500
                                    mt-4 border border-[#0A49BA]
                                    rounded-[5px] py-4 px-5 text-[#0A49BA] hover:text-white
                                    font-medium hover:bg-[#0041B1] hover:opacity-[0.9] transition-all"
                                >   
                                    Edit Password 
                            </button>
                        </div>

                        <div className='mt-8'>
                            <h6 className='font-Lota400 text-[#010918]'>Change Transaction Pin</h6>
                            <p className='text-[#B9BDC8] font-Lota300 text-sm'>Individual information about this customer</p>
                            <button
                                     onClick={PinModal}
                                    type="submit"
                                    disabled={false}
                                    className="bg-white 
                                    text-sm
                                    font-Graphix500
                                    mt-4 border border-[#0A49BA]
                                    rounded-[5px] py-4 px-5 text-[#0A49BA] hover:text-white
                                    font-medium hover:bg-[#0041B1] hover:opacity-[0.9] transition-all"
                                >   
                                    Edit Transaction Pin 
                            </button>
                        </div>


                </div>
            </div>
        </>
     );
}
 
export default SettingsPage;