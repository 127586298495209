import React,{useState} from 'react'
import more_icon from '../../assets/icons/more.svg'
// import nodata_icon from '../../assets/icons/Nodata.svg'
import PaginationComponent from '../../components/Pagination/Pagination';
import { useNavigate } from 'react-router-dom';

const SchedulePage = () => {

    let PageSize = 10;
    let count = 80;
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate()
  
    const getMoreItemsByOffset = (page) => {
      setCurrentPage(page)
    }

    const viewSchedule = () =>{
        navigate('/schedule/1')
    }
  

    return ( 
        <>
          <div className='mt-5 md:mt-10 bg-[#FFFFFF] rounded-xl py-6 h-full'>
                   
                <div className="overflow-x-auto relative mt-6">
                    <table className="w-full text-left">
                        <thead className="text-[#010918] font-Lota400 bg-white text-sm border-b-[#D8D8D8] border-t-[#D8D8D8] border-b-[0.25px] border-t-[0.25px]">
                        <tr>
                            <th scope="col" className="py-4 px-6">
                            S/N
                            </th>
                            <th scope="col" className="py-4 px-6">
                            Scheduled Bill
                            </th>
                            <th scope="col" className="py-4 px-6">
                            Amount
                            </th>
                            <th scope="col" className="py-4 px-6">
                            Recipient
                            </th>
                            <th scope="col" className="py-4 px-6">
                            Start Date
                            </th> 
                            <th scope="col" className="py-4 px-6">
                            Due Date
                            </th>
                            <th scope="col" className="py-4 px-6 ">
                            Action
                            </th>
                        </tr>
                    </thead>

                     <tr className="bg-white text-sm font-Lota400 hover:bg-[#cfdffc] hover:bg-opacity-[0.3] border-b-[#D8D8D8] border-t-[#D8D8D8] border-b-[0.25px] border-t-[0.25px]">
                            <td
                              className="py-4 px-6 text-[#010918] whitespace-nowrap"
                            >
                            1
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            DSTV subscription
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            <span className='text-[10px]'>NGN</span>200.00
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            DSTV
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            10-10-2021
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            10-10-2021
                            </td>
                            <td className="py-4 px-6 text-[#010918] cursor-pointer">
                              <img src={more_icon}  alt="more" onClick={viewSchedule} /> 
                            </td>       
                     </tr>

                     <tr className="bg-white text-sm font-Lota400 hover:bg-[#cfdffc] hover:bg-opacity-[0.3]  border-b-[#D8D8D8] border-t-[#D8D8D8] border-b-[0.25px] border-t-[0.25px]">
                            <td
                              className="py-4 px-6 text-[#010918] whitespace-nowrap"
                            >
                            2
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            DSTV subscription
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            <span className='text-[10px]'>NGN</span>200.00
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            DSTV
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            10-10-2021
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            10-10-2021
                            </td>
                            <td className="py-4 px-6 text-[#010918] text-center cursor-pointer">
                                <img src={more_icon}  alt="more" onClick={viewSchedule} />
                            </td>       
                     </tr>

                     <tr className="bg-white text-sm font-Lota400 hover:bg-[#cfdffc] hover:bg-opacity-[0.3] border-b-[#D8D8D8] border-t-[#D8D8D8] border-b-[0.25px] border-t-[0.25px]">
                            <td
                              className="py-4 px-6 text-[#010918] whitespace-nowrap"
                            >
                            3
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            DSTV subscription
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            <span className='text-[10px]'>NGN</span>200.00
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            DSTV
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            10-10-2021
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            10-10-2021
                            </td>
                            <td className="py-4 px-6 text-[#010918] cursor-pointer">
                                 <img src={more_icon}  alt="more" onClick={viewSchedule} />
                            </td>       
                     </tr>

                     <tr className="bg-white text-sm font-Lota400 hover:bg-[#cfdffc] hover:bg-opacity-[0.3]  border-b-[#D8D8D8] border-t-[#D8D8D8] border-b-[0.25px] border-t-[0.25px]">
                            <td
                              className="py-4 px-6 text-[#010918] whitespace-nowrap"
                            >
                            4
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            DSTV subscription
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            <span className='text-[10px]'>NGN</span>200.00
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            DSTV
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            10-10-2021
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            10-10-2021
                            </td>
                            <td className="py-4 px-6 text-[#010918] cursor-pointer">
                             <img src={more_icon}  alt="more" onClick={viewSchedule} />
                            </td>       
                     </tr>


             
                   </table>
                </div>

                <div className="flex justify-end mt-6 pr-8">
                     <PaginationComponent
                    currentPage={currentPage}
                    totalCount={count}
                    pageSize={PageSize}
                    onPageChange={(page) => getMoreItemsByOffset(page)}
                    />
                </div>
             </div>
        </>
     );
}
 
export default SchedulePage;