

import { createSlice } from '@reduxjs/toolkit'
import { createSingleAirtime, createSingleData, fetchDataBundles } from './topupActions'

const initialState = {
  value: 0,
  loader: false,
  databundles: [],
  mobileNetwork: "",
  isInstructionSuccess: false
}

export const topupSlice = createSlice({
  name: 'topup',
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    clearDataBundles: (state) =>{
      state.databundles = []
    },
    clearInstructionSuccess: (state) =>{
      state.isInstructionSuccess = false
    }
  },
  extraReducers: {
    // create single airtime -------------------- ---------------------------------------------------------------
    [createSingleAirtime.pending]: (state) => {
      state.loader = true
    },
    [createSingleAirtime.fulfilled]: (state, { payload }) => {
      state.loader = false
      state.isInstructionSuccess = true
    },
    [createSingleAirtime.rejected]: (state, { payload }) => {
      state.loader = false
    },

    // create single Data
    [createSingleData.pending]: (state) =>{
      state.loader = true
    },
    [createSingleData.fulfilled]: (state) =>{
        state.loader = false
        state.isInstructionSuccess = true
    },
    [createSingleData.rejected]: (state) =>{
      state.loader = false
    },
    // fetch data bundles
    [fetchDataBundles.pending]: (state) =>{

    },
    [fetchDataBundles.fulfilled]: (state,{payload}) =>{
        state.databundles = payload.products
        state.mobileNetwork = payload.opts.operator
    },
    [fetchDataBundles.rejected]: (state) =>{

    }
  },
})

// Action creators are generated for each case reducer function
export const { increment, decrement, clearDataBundles, clearInstructionSuccess } = topupSlice.actions

export default topupSlice.reducer