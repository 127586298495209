import React from 'react'
import chevron_right from '../../assets/icons/chevron_right.svg'
import electricity_icon from '../../assets/icons/electricity.svg'
// import flight_icon from '../../assets/icons/flight.svg'
import internet_icon from '../../assets/icons/internet.svg'
import cable_icon from '../../assets/icons/cable.svg'
import { Link } from 'react-router-dom'


const PayBillsPage = () => {



    return ( 
        <>
    
            {/* main */}
            <div className='mt-5 md:mt-10 bg-[#FFFFFF] border border-[#D8D8D8] rounded-xl py-8 px-6 h-full'>
                
                    <div className='grid md:grid-cols-2 gap-4'>
                        <div className='shadow-card2 rounded-[10px] py-6 px-6'>
                            <div className='bg-[#0A49BA] w-[60px] h-[60px] rounded-full flex items-center justify-center'>
                                <img src={electricity_icon} alt="electricity icon" />
                            </div>
                              <h6 className='text-[#010918] font-Lota600 mt-5 text-base'>Electricity Bills</h6>
                             <p className='text-[#425466] font-Lota400 text-xs mt-1'>Buy your electricity Unit</p>

                            <div className='flex justify-end mt-6'>
                                <Link to="/paybills/electricity" className='bg-[#F0F0F0] cursor-pointer w-[30px] h-[30px] rounded-full flex justify-center items-center border-[0.54px] border-[#D8D8D8]'>
                                    <img src={chevron_right} alt="chevron right icon" />
                                </Link>
                            </div> 
                        </div>

                        <div className='shadow-card2 rounded-[10px] py-6 px-6'>
                            <div className='bg-[#0A49BA] w-[60px] h-[60px] rounded-full flex items-center justify-center'>
                                <img src={internet_icon} alt="internet icon" />
                            </div>
                              <h6 className='text-[#010918] font-Lota600 mt-5 text-base'>Internet Services</h6>
                             <p className='text-[#425466] font-Lota400 text-xs mt-1'>Subscribe for your WIFI, MIFI, Smile...</p>

                            <div className='flex justify-end mt-6'>
                                <Link to="/paybills/internet" className='bg-[#F0F0F0] cursor-pointer w-[30px] h-[30px] rounded-full flex justify-center items-center border-[0.54px] border-[#D8D8D8]'>
                                    <img src={chevron_right} alt="chevron right icon" />
                                </Link>
                            </div> 
                        </div>

                        <div className='shadow-card2 rounded-[10px] py-6 px-6'>
                            <div className='bg-[#0A49BA] w-[60px] h-[60px] rounded-full flex items-center justify-center'>
                                <img src={cable_icon} alt="cable icon" />
                            </div>
                              <h6 className='text-[#010918] font-Lota600 mt-5 text-base'>Cable Television</h6>
                             <p className='text-[#425466] font-Lota400 text-xs mt-1'>Pay for your DSTV, GOTV, Startimes.</p>

                            <div className='flex justify-end mt-6'>
                                <Link to="/paybills/cables" className='bg-[#F0F0F0] cursor-pointer w-[30px] h-[30px] rounded-full flex justify-center items-center border-[0.54px] border-[#D8D8D8]'>
                                    <img src={chevron_right} alt="chevron right icon" />
                                </Link>
                            </div> 
                        </div>                                      
                    </div>          
    
            </div>    
        </>
     );
}
 
export default PayBillsPage;