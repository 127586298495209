import React from 'react'
import dstv from '../../assets/icons/Dstv_1.svg'


const ViewSchedulePage = () => {
    return ( 
        <>
            <div className='mt-5 md:mt-10'>

                <div>
                        <h6 className='text-[#040316] font-Lota400'>View Schedule</h6>
                </div>

                <div className='bg-[#FFFFFF] rounded-xl py-8 px-8 mt-4'>

                    <div className='flex gap-4 items-center '>
                        <div>
                            <img src={dstv} alt="dstv" />
                        </div>
                        <div>
                        <h6 className='text-[#040316] font-Lota600'>Dstv Subscription</h6>
                        </div>
                    </div>

                    <div className='flex justify-between items-center mt-6'>
                        <div>
                            <h6 className='text-[#6B7588] font-Lota400'>Subscription Type</h6>
                        </div>
                        <div>
                            <h6 className='text-[#040316] font-Lota400 text-sm'>Premium</h6>
                        </div>
                    </div>

                    <div className='flex justify-between items-center mt-6'>
                        <div>
                            <h6 className='text-[#6B7588] font-Lota400'>Amount</h6>
                        </div>
                        <div>
                            <h6 className='text-[#040316] font-Lota400 text-sm'>#5,000</h6>
                        </div>
                    </div>

                    <div className='flex justify-between items-center mt-6'>
                        <div>
                            <h6 className='text-[#6B7588] font-Lota400'>Start Date</h6>
                        </div>
                        <div>
                            <h6 className='text-[#040316] font-Lota400 text-sm'>24, July 2002</h6>
                        </div>
                    </div>

                    <div className='flex justify-between items-center mt-6'>
                        <div>
                            <h6 className='text-[#6B7588] font-Lota400'>End Date</h6>
                        </div>
                        <div>
                            <h6 className='text-[#040316] font-Lota400 text-sm'>24, July 2002</h6>
                        </div>
                    </div>


                    <div className='flex justify-between items-center mt-6'>
                        <div>
                            <h6 className='text-[#6B7588] font-Lota400'>Due Date</h6>
                        </div>
                        <div>
                            <h6 className='text-[#040316] font-Lota400 text-sm'>24, July 2002</h6>
                        </div>
                    </div>

                </div>
            </div>
        </>
     );
}
 
export default ViewSchedulePage;