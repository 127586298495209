

import { createSlice } from '@reduxjs/toolkit'
import { createSingleCable, createSingleElectricity, createSingleInternet, fetchCableBundles, fetchElectricityProducts, fetchInternetBundles, fetchInternetServices } from './billActions'

const initialState = {
  value: 0,
  loader: false,
  products: [],
  services: [],
  packages: [],
  isBillInstructionSuccess: false,
  internetBundle: []
}

export const billSlice = createSlice({
  name: 'bills',
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    clearPackageBundles: (state) =>{
      state.packages = []
    },
     clearBillInstructionSuccess: (state) =>{
      state.isBillInstructionSuccess = false
    },
    clearInternetBundles: (state) =>{
      state.internetBundle = []
    },
  },
  extraReducers: {
    // fetch electricity products -------------------- ---------------------------------------------------------------
    [fetchElectricityProducts.pending]: (state) => {
      state.loader = true
    },
    [fetchElectricityProducts.fulfilled]: (state, { payload }) => {
      state.loader = false
      state.products = payload.products
    },
    [fetchElectricityProducts.rejected]: (state, { payload }) => {
      state.loader = false
    },
    // fetch internet services -------------------------
    [fetchInternetServices.pending]: (state) =>{
      state.loader = true
    },
    [fetchInternetServices.fulfilled]: (state,{payload}) =>{
        state.loader = false
        state.services = payload.products
    },
    [fetchInternetServices.rejected]: (state) =>{
        state.loader = false
    },
    // create single electricity
    [createSingleElectricity.pending]: (state) =>{
        state.loader = true
    },
    [createSingleElectricity.fulfilled]: (state) =>{
        state.loader = false
        state.isBillInstructionSuccess = true
    },
    [createSingleElectricity.rejected]: (state) =>{
        state.loader = false
    },
    // fetch cable bindles
    [fetchCableBundles.pending]: (state) =>{

    },
    [fetchCableBundles.fulfilled]: (state,{payload}) =>{
        state.packages = payload.upgrades
    },
    [fetchCableBundles.rejected]: (state) =>{
      
    },
    // create single cables
    [createSingleCable.pending]: (state) =>{
        state.loader = true
    },
    [createSingleCable.fulfilled]: (state) =>{
        state.loader = false
        state.isBillInstructionSuccess = true
    },
    [createSingleCable.rejected]: (state) =>{
        state.loader = false
    },
    // fetch internet bundles
    [fetchInternetBundles.pending]: (state) =>{

    },
    [fetchInternetBundles.fulfilled]: (state,{payload}) =>{
        state.internetBundle = payload.products
    },
    [fetchInternetBundles.rejected]: (state) =>{

    },
       // create single internet
    [createSingleInternet.pending]: (state) =>{
        state.loader = true
    },
    [createSingleInternet.fulfilled]: (state) =>{
        state.loader = false
        state.isBillInstructionSuccess = true
    },
    [createSingleInternet.rejected]: (state) =>{
        state.loader = false
    }
  },
})

// Action creators are generated for each case reducer function
export const { increment, decrement,clearBillInstructionSuccess,clearPackageBundles, clearInternetBundles } = billSlice.actions

export default billSlice.reducer