

import { createSlice } from '@reduxjs/toolkit'
import { fetchTransactionsWithFilter } from './transactionActions'

const initialState = {
  value: 0,
  loading: false,
  transactions: []
}

export const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
  },
  extraReducers: {
    // fetch transactions
    [fetchTransactionsWithFilter.pending]: (state) =>{
        state.loading = true
    },
    [fetchTransactionsWithFilter.fulfilled]: (state,{payload}) =>{
        state.loading = false
        state.transactions = payload
    },
    [fetchTransactionsWithFilter.rejected]: (state) =>{
        state.loading = false
    }
  },
})

// Action creators are generated for each case reducer function
export const { increment, decrement } = transactionsSlice.actions

export default transactionsSlice.reducer