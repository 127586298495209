import React from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SideWrapper from './components/SideWrapper/SideWrapper';
import NotificationPage from './pages/ Notification/notification';
import CreatePinPage from './pages/Auth/createPin';
import ForgotPage from './pages/Auth/forgot';
import LoginPage from './pages/Auth/login';
import RegisterPage from './pages/Auth/register';
import ResetPasswordPage from './pages/Auth/reset';
import DashboardPage from './pages/Dashboard/dashboard';
import ElectricityPage from './pages/PayBills/Electricity';
import PayBillsPage from './pages/PayBills/paybills';
import SchedulePage from './pages/Schedules/schedule';
import ViewSchedulePage from './pages/Schedules/viewSchedule';
import SettingsPage from './pages/Settings/settings';
import AirtimeTopUpPage from './pages/TopUp/AirtimeTopUp';
import DataTopUpPage from './pages/TopUp/DataTopUp';
import BulkTransactionsPage from './pages/Transactions/BulkTransaction';
import TransactionsPage from './pages/Transactions/transactions';
import VerifyEmailPage from './pages/Auth/verifyEmail';
import InternetPage from './pages/PayBills/Internet';
import CablesPage from './pages/PayBills/Cables';
import ElectricityTopUpPage from './pages/PayBills/ElectricityTopUp';
import CableTopUpPage from './pages/PayBills/CableTopUp';
import InternetTopUpPage from './pages/PayBills/InternetTopUp';


function App() {
  return (
    <div>
       <Router>
        <Routes>
          <Route path="/" exact element={<LoginPage />} />
          <Route path="/forgotpassword"  element={<ForgotPage />} />
          <Route path="/resetpassword/"  element={<ResetPasswordPage />} />
          <Route path="/signup"  element={<RegisterPage />} />
          <Route path="/auth/email/verify"  element={<VerifyEmailPage />} />
          <Route path="/auth/create-pin"  element={<CreatePinPage />} />
          <Route path="/dashboard"  element={
                    <SideWrapper title="Dashboard" children={<DashboardPage/>} />
                 }
            />
           <Route path="/top-up/airtime"  element={
                    <SideWrapper title="Top-Up" children={<AirtimeTopUpPage/>} />
                }
            />

            <Route path="/top-up/data"  element={
                    <SideWrapper title="Top-Up" children={<DataTopUpPage/>} />
                }
            />

          <Route path="/paybills"  element={
                  <SideWrapper title="PayBills" children={<PayBillsPage/>} />
                }
              />
            
          <Route path="/paybills/electricity"  element={
                  <SideWrapper title="PayBills" children={<ElectricityPage/>} />
                }
            />

          <Route path="/paybills/electricity/:id"  element={
                  <SideWrapper title="PayBills" children={<ElectricityTopUpPage/>} />
                }
            />

          <Route path="/paybills/internet"  element={
                  <SideWrapper title="PayBills" children={<InternetPage/>} />
                }
            />


              <Route path="/paybills/cables"  element={
                  <SideWrapper title="PayBills"  children={<CablesPage/>} />
                }
            />

            <Route path={`/paybills/:plan-subscription`}  element={
                  <SideWrapper title="PayBills" children={<CableTopUpPage/>} />
                }
            />

          <Route path={`/paybills/internet/:plan`}  element={
                  <SideWrapper title="PayBills" children={<InternetTopUpPage/>} />
                }
            />

          <Route path="/notification"  element={
                <SideWrapper title="Notification" children={<NotificationPage/>} />
              }
            />

          <Route path="/schedules"  element={
                <SideWrapper title="Schedules" children={<SchedulePage/>} />
              }
            />

          <Route path="/schedule/:id"  element={
                <SideWrapper title="Schedule" children={<ViewSchedulePage/>} />
              }
            />
           <Route path="/transactions"  element={
                <SideWrapper title="Transactions" children={<TransactionsPage/>} />
              }
            />

              <Route path="/transactions/bulk/:id"  element={
                <SideWrapper title="Transactions" children={<BulkTransactionsPage/>} />
              }
            />
           <Route path="/settings"  element={
                  <SideWrapper title="Settings" children={<SettingsPage/>} />
                }
              />
         </Routes>
        </Router>
    </div>
  );
}

export default App;
