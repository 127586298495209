

import { createSlice } from '@reduxjs/toolkit'
import { resendVerifyEmail, updateUserPassword, updateUserPin, userCreatePin, userForgot, userLogin, userRegister, userResetPassword, userVerifyID, userWalletDetails, verifyEmail } from './authActions'

const initialState = {
  value: 0,
  loading: false,
  token: '',
  isAuthenticated: false,
  userInfo: null,
  useremail: "",
  isResetSuccess: false,
  signup_success: false,
  isVerified: false,
  isSetPin: false,
  isIdVerified: false,
  virtualAccountNumber: "",
  balance: 0,
  accountActive: false
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    clearSignUpSuccess: (state) => {
      state.signup_success = false
    },
    UserEmail : (state, {email}) =>{
      state.useremail = email
    },
    clearIsResetSuccess: (state) =>{
      state.isResetSuccess = false
    },
    clearIsVerified: (state) =>{
      state.isVerified = false
    },
    Logout: (state) => {
      state.isAuthenticated = false
      localStorage.setItem('token', '')
    },
  },
  extraReducers: {
    // login user -------------------- ---------------------------------------------------------------
    [userLogin.pending]: (state) => {
      state.loading = true
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.token = payload.token
      state.isAuthenticated = true
      localStorage.setItem('token', payload.token)
      state.userInfo = payload.profile
      state.isSetPin = payload.profile.isSetPin
      state.isVerified = payload.profile.isVerified
      state.isIdVerified = payload.profile.isIdVerified
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false
      state.isAuthenticated = false
    },

       // Forgot Passowrd ------------------ --------------------------------------------------------
       [userForgot.pending]: (state) => {
        state.loading = true
      },
      [userForgot.fulfilled]: (state, { payload }) => {
        state.loading = false
      },
      [userForgot.rejected]: (state) => {
        state.loading = false
      },

      // user reset password
       [userResetPassword.pending]: (state) =>{
            state.loading = true
        },
        [userResetPassword.fulfilled]:(state) =>{
            state.loading = false
            state.isResetSuccess = true
        },
        [userResetPassword.rejected]:(state) =>{
            state.loading = false
        },

     // Register User ------------------ --------------------------------------------------------
      [userRegister.pending]: (state) => {
        state.loading = true
      },
      [userRegister.fulfilled]: (state, { payload }) => {
        state.loading = false
        state.signup_success = true
        state.token = payload.token
        state.isAuthenticated = true
        localStorage.setItem('token', payload.token)
        state.userInfo = payload.profile
        state.isSetPin = payload.profile.isSetPin
        state.isVerified = payload.profile.isVerified
        state.isIdVerified = payload.profile.isIdVerified
      },
      [userRegister.rejected]: (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.signup_success = false
      },

    // create pin ---------------------- --------------------------
    [userCreatePin.pending]: (state) =>{
      state.loading = true
    },
    [userCreatePin.fulfilled]: (state) =>{
      state.loading = false
      state.isSetPin = true
    },
    [userCreatePin.rejected]: (state) =>{
      state.loading = false
    },

    // verify email ---------- ---------
    [verifyEmail.pending]: (state) =>{
      state.loading = true
    },
    [verifyEmail.fulfilled] : (state) =>{
      state.loading = false
      state.isVerified = true
    },
    [verifyEmail.rejected]: (state) =>{
      state.loading = false
    },
    // resend verification link ------------- -----------------------
    [resendVerifyEmail.pending] : (state) =>{
      state.loading = true
    },
    [resendVerifyEmail.fulfilled]:(state) =>{
      state.loading = false
    },
    [resendVerifyEmail.rejected]: (state) =>{
      state.loading = false
    },

    // change password ----- ----- ----- 
    [updateUserPassword.pending]: (state) =>{
      state.loading = true
    },
    [updateUserPassword.fulfilled]: (state) =>{
      state.loading = false
      state.isAuthenticated = false
    },
    [updateUserPassword.rejected]: (state) =>{
      state.loading = false
    },

    // change pin ----- ----- ----- 
    [updateUserPin.pending]: (state) =>{
      state.loading = true
    },
    [updateUserPin.fulfilled]: (state) =>{
      state.loading = false
    },
    [updateUserPin.rejected]: (state) =>{
      state.loading = false
    },

    // BVN verification -----
    [userVerifyID.pending]: (state) =>{
      state.loading = true
    },
    [userVerifyID.fulfilled]: (state) =>{
      state.loading = false
      state.isIdVerified = true
    },
    [userVerifyID.rejected]: (state) =>{
      state.loading = false
    },
    // user get wallet details
    [userWalletDetails.pending]: (state) =>{

    },
    [userWalletDetails.fulfilled]: (state,{payload}) =>{
      state.virtualAccountNumber = payload.virtualAccountNumber
      state.balance = payload.balance
      state.accountActive = payload.isActive
    },
    [userWalletDetails.rejected]: (state) =>{

    }
  },
})

// Action creators are generated for each case reducer function
export const { increment, decrement, Logout, clearIsResetSuccess, clearIsVerified, UserEmail,clearSignUpSuccess } = authSlice.actions

export default authSlice.reducer