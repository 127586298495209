import React,{useState} from 'react'
// import add_icon from '../../assets/icons/add_grey.svg'
// import trash_icon from '../../assets/icons/trash_icon.svg'
import download_icon from '../../assets/icons/download_white.svg'
import upload_icon from '../../assets/icons/upload.svg'
import Modal from '../../components/Modals/modal'
import { Form, Formik } from 'formik'
import { PinValidator, singleElectricityValidator } from '../../validationSchema/validator'
import eye from '../../assets/icons/eye.svg'
import eye_off from '../../assets/icons/eye_off.svg'
import { useDispatch, useSelector } from 'react-redux'
import { userCreatePin } from '../../redux/Auth/authActions'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { createSingleElectricity } from '../../redux/Bills/billActions'


const ElectricityTopUpPage = () => {

    const [showScheduleModal, setshowScheduleModal] = useState(false)
    const [showSingleScheduleModal, setshowSingleScheduleModal] = useState(false)
    const [checkedBulk, setCheckedBulk] = useState(false);
    const [checkedSingle, setCheckedSingle] = useState(false);
    const dispatch = useDispatch();
    const { loading, isSetPin} = useSelector((state) => state.auth);
    const { products, loader} = useSelector((state) => state.bill);
    const {id} = useParams()

    const [type, setType] = useState("prepaid")

    const [singlePeriod, setSinglePeriod] = useState("DAILY")
    const [singleStartDate, setSingleStartDate] = useState()
    const [singleEndDate, setSingleEndDate] = useState("")
    const [singlePhoneNumber, setSinglePhoneNumber] = useState("")
    const [singleMeterNumber, setSingleMeterNumber] = useState("")
    const [singleAmount, setSingleAmount] = useState(0)
    const [singleEmail, setSingleEmail] = useState("")

    let providerName = products.length ? products.find((val) => val.product_id === id).name : 'Light Distribution'

    const toggleType = (val) =>{
        setType(val)
    }

    const scheduleSingleModal = () =>{
        setshowSingleScheduleModal(!showSingleScheduleModal)
    }

    const scheduleModal = () =>{
        setshowScheduleModal(!showScheduleModal)
    }

    const ToggleScheduleBulk = (val) =>{
       setCheckedBulk(val)
       if(val){
         setshowScheduleModal(true)
       }
    }

    const ToggleScheduleSingle = (val) =>{
        setCheckedSingle(val)
        if(val){
            setshowSingleScheduleModal(true)
        }
    }

    const [showPinModal, setShowPinModal] = useState(false)

    const PinModal = () => {
        setShowPinModal(!showPinModal)
    }


    const [passwordShown, setPasswordShown] = useState(false)
    const [confirmpasswordShown, setconfirmPasswordShown] = useState(false);

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true)
    }

    const toggleConfirmPasswordVisiblity = () => {
        setconfirmPasswordShown(confirmpasswordShown ? false : true);
     };

    const handleSubmit = (values) => {
        dispatch(userCreatePin(values))

        setTimeout(()=>{
            setShowPinModal(false)
        },1000)
    }

    const processBulkAirtime = ()=>{
        setShowPinModal(true)
    }

    const handleSingleElectricity = (values) =>{
        console.log(values)

        // make api call
        let res;
        if(checkedSingle){
            res = {
                amount: values.amount,
                cardNumber: values.meterNumber,
                receiverPhone: '234' + values.phoneNumber.substring(1),
                isReccurring: checkedSingle,
                scheduledTime: moment().format('YYYY-MM-DDTH:MM'),
                reccurringType: singlePeriod,
                startDate : singleStartDate,
                endDate: singleEndDate,
                pin: values.pin,
                providerName: providerName,
                isPrepaid: type === "prepaid" ? true : false,
                sku : id,
                receiverEmail: values.email
            } 
        }
        else{
            res = {
                amount: values.amount,
                cardNumber: values.meterNumber,
                receiverPhone: '234' + values.phoneNumber.substring(1),
                isReccurring: checkedSingle,
                scheduledTime: moment().format('YYYY-MM-DDTH:mm'),
                pin: values.pin,
                providerName: providerName,
                isPrepaid: type === "prepaid" ? true : false,
                sku : id,
                receiverEmail: values.email
            }
        }
        console.log(res)
        dispatch(createSingleElectricity(res))
        
    }

    return ( 
        <>
        {/* schedule single electricity modal */}
         <Modal show={showSingleScheduleModal} title="Schedule Single Electricity"  showModal={scheduleSingleModal} 
            >
                <div className='mt-4 max-w-sm mx-auto'>
                     <div>
                        <label htmlFor='period' className="block mb-2 text-[#333F51] text-sm font-Graphix500">
                            Select Period
                        </label>
                            <select name="period" 
                            onChange={(e) => setSinglePeriod(e.target.value)}
                            className="appearance-none w-full cursor-pointer font-Graphix400 placeholder:text-[#8896AB] placeholder:text-sm text-[#8896AB] text-sm focus:border-[#D5DAE1] shadow-input  focus:outline-none focus:shadow-input rounded-lg border border-[#D5DAE1] bg-white py-4 px-4">
                            <option value="" disabled>Select a period</option>
                            <option value="DAILY">Daily</option>
                            <option value="WEEKLY">Weekly</option>
                            <option value="MONTHLY">Monthly</option>
                        </select>
                    </div>
                    <div className='mt-4'>
                        <label htmlFor='startDate' className="block mb-2 text-[#333F51] text-sm font-Graphix500">
                            Start Date
                        </label>
                            <input name="startDate" type="datetime-local" placeholder='Start Date'
                            onChange={(e) => setSingleStartDate(e.target.value)}
                            className="appearance-none w-full cursor-pointer font-Graphix400 placeholder:text-[#8896AB] placeholder:text-sm text-[#8896AB] text-sm focus:border-[#D5DAE1] shadow-input  focus:outline-none focus:shadow-input rounded-lg border border-[#D5DAE1] bg-white py-4 px-4" />
                    </div>
                    <div className='mt-4'>
                        <label htmlFor='endDate' className="block mb-2 text-[#333F51] text-sm font-Graphix500">
                            End Date
                        </label>
                            <input name="endDate" type="datetime-local" placeholder='End Date' 
                             onChange={(e) => setSingleEndDate(e.target.value)}
                            className="appearance-none w-full cursor-pointer font-Graphix400 placeholder:text-[#8896AB] placeholder:text-sm text-[#8896AB] text-sm focus:border-[#D5DAE1] shadow-input  focus:outline-none focus:shadow-input rounded-lg border border-[#D5DAE1] bg-white py-4 px-4" />
                    </div>

                    <div className="mt-6 mb-4">
                        <button
                            type="submit"
                            disabled={false}
                            className="bg-[#0041B1] 
                            disabled:bg-[#0041B1] 
                            disabled:opacity-[0.7]
                            text-sm
                            rounded-lg w-full py-4 text-[#F0FDF4] font-medium hover:bg-[#0041B1] hover:opacity-[0.9] transition-all"
                        >
                            Confirm
                        </button>
                    </div>

                </div>
        </Modal>

        {/* schedule bulk electricity modal */}
        <Modal show={showScheduleModal} title="Schedule Bulk Electricity"  showModal={scheduleModal} 
            >
                <div className='mt-4 max-w-sm mx-auto'>
                     <div>
                        <label htmlFor='period' className="block mb-2 text-[#333F51] text-sm font-Graphix500">
                            Select Period
                        </label>
                            <select name="period" 
                            className="appearance-none w-full cursor-pointer font-Graphix400 placeholder:text-[#8896AB] placeholder:text-sm text-[#8896AB] text-sm focus:border-[#D5DAE1] shadow-input  focus:outline-none focus:shadow-input rounded-lg border border-[#D5DAE1] bg-white py-4 px-4">
                            <option value="" selected disabled>Select an period</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Monthly">Monthly</option>
                            <option value="Yearly">Yearly</option>
                        </select>
                    </div>
                    <div className='mt-4'>
                        <label htmlFor='period' className="block mb-2 text-[#333F51] text-sm font-Graphix500">
                            Start Date
                        </label>
                            <input name="period" type="date" placeholder='Start Date'
                            className="appearance-none w-full cursor-pointer font-Graphix400 placeholder:text-[#8896AB] placeholder:text-sm text-[#8896AB] text-sm focus:border-[#D5DAE1] shadow-input  focus:outline-none focus:shadow-input rounded-lg border border-[#D5DAE1] bg-white py-4 px-4" />
                    </div>
                    <div className='mt-4'>
                        <label htmlFor='period' className="block mb-2 text-[#333F51] text-sm font-Graphix500">
                            End Date
                        </label>
                            <input name="period" type="date" placeholder='End Date' 
                            className="appearance-none w-full cursor-pointer font-Graphix400 placeholder:text-[#8896AB] placeholder:text-sm text-[#8896AB] text-sm focus:border-[#D5DAE1] shadow-input  focus:outline-none focus:shadow-input rounded-lg border border-[#D5DAE1] bg-white py-4 px-4" />
                    </div>

                    <div className="mt-6 mb-4">
                        <button
                            type="submit"
                            disabled={false}
                            className="bg-[#0041B1] 
                            disabled:bg-[#0041B1] 
                            disabled:opacity-[0.7]
                            text-sm
                            rounded-lg w-full py-4 text-[#F0FDF4] font-medium hover:bg-[#0041B1] hover:opacity-[0.9] transition-all"
                        >
                            Confirm
                        </button>
                    </div>

                </div>
        </Modal>


        <Modal show={showPinModal} title="Transaction Pin" showModal={PinModal} >
                <div className='mt-2'>
                  <p className='text-[#898CA6] font-Graphix500 text-sm text-center'>Set Your 4 digit Transaction Pin.</p>
                </div>
                <div className='max-w-sm mx-auto'>
                     <Formik
                    onSubmit={(values, { setSubmitting }) =>
                    handleSubmit(values, setSubmitting)
                    }
                    validationSchema={PinValidator}
                    initialValues={{
                    pin: '',
                    confirm_pin: '',
                    }}
                >
                    {({
                    handleChange,
                    isSubmitting,
                    handleSubmit,
                    handleBlur,
                    values,
                    touched,
                    errors,
                    }) => (
                    <Form className="mt-6" onSubmit={handleSubmit}>
                        <div>
                        <label htmlFor='pin' className="block mb-2 text-[#333F51] text-sm font-medium">
                            Enter Pin
                        </label>
                        <div className='relative'>
                        <input
                            type={passwordShown ? 'text' : 'password'}
                            name="pin"
                            placeholder="Enter your pin"
                            className={
                            touched.pin && errors.pin
                                ? 'appearance-none w-full placeholder:text-[#8896AB] placeholder:text-sm  text-[#8896AB] text-sm focus:border-error focus:outline-none rounded-lg border border-error bg-white py-4 px-4'
                                : 'appearance-none w-full placeholder:text-[#8896AB] placeholder:text-sm text-[#8896AB] text-sm focus:border-[#D5DAE1] shadow-input  focus:outline-none focus:shadow-input rounded-lg border border-[#D8D8D8] bg-white py-4 px-4'
                            }
                            value={values.pin}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <img
                            onClick={togglePasswordVisiblity}
                            src={passwordShown ? eye_off : eye}
                            alt="eye"
                            className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                            />

                        </div>
                      
                        {touched.pin && errors.pin ? (
                            <small className="text-[#FF002A] font-[300] text-[10px] pt-[4px]">
                                {touched.pin && errors.pin}
                            </small>
                        ) : null}
                        </div>

                        <div className="mt-5">
                        <label htmlFor='confirm_pin' className="block mb-2 text-[#333F51] text-sm font-medium">
                            Confirm Pin
                        </label>
                        <div className="relative">
                            <input
                            type={confirmpasswordShown ? 'text' : 'password'}
                            name="confirm_pin"
                            placeholder="Confirm your pin"
                            className={
                                touched.confirm_pin && errors.confirm_pin
                                ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#8896AB] text-sm focus:border-error focus:outline-none rounded-lg border border-error bg-white py-4 px-4'
                                : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#8896AB] text-sm focus:border-[#D5DAE1] focus:outline-none shadow-input focus:shadow-input rounded-lg border border-[#D8D8D8] bg-white py-4 px-4'
                            }
                            value={values.confirm_pin}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            />
                            <img
                            onClick={toggleConfirmPasswordVisiblity}
                            src={confirmpasswordShown ? eye_off : eye}
                            alt="eye"
                            className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                            />
                        </div>
                        {touched.confirm_pin && errors.confirm_pin ? (
                            <small className="text-[#FF002A] font-[300] text-[10px] pt-[4px]">
                                {touched.confirm_pin && errors.confirm_pin}
                            </small>
                        ) : null}
                        </div>

                        <div className="mt-6">
                        <button
                            type="submit"
                            disabled={loading}
                            className="bg-[#0041B1] 
                            disabled:bg-[#0041B1] 
                            disabled:opacity-[0.7]
                            text-sm
                            rounded-lg w-full py-4 text-[#F0FDF4] font-medium hover:bg-[#0041B1] hover:opacity-[0.9] transition-all"
                        >
                            Confirm
                        </button>
                        </div>

                        <div onClick={PinModal} className='text-center mt-5'>
                            <p className='text-[#8896AB] text-sm font-medium cursor-pointer'>Cancel
                             </p>
                        </div>

                    
                    </Form>
                    )}
                     </Formik>
                </div>
            </Modal>

             <div className='mt-16 bg-[#FFFFFF] rounded-xl py-8'>

                <div className='px-6'>
                    <h6 className='text-[#010918] font-Lota600 text-base'>Electricity Bills ({providerName.toUpperCase()})</h6>

                    <div className='flex gap-4 mt-4'>
                        <div className='bg-[#F6F6F8] flex gap-3 items-center border border-[#CECEDE] rounded-lg py-3 px-4'>
                          <input id="prepaid" type="radio" value="prepaid" name="type" checked={type === "prepaid"} onChange={(e) =>toggleType(e.target.value)}
                            className="w-4 h-4 cursor-pointer" />
                           <label for="prepaid" className='text-[#010918] font-Lota400 text-sm'>Prepaid</label> 
                        </div>
                        <div className='bg-[#F6F6F8] flex gap-3 items-center  border border-[#CECEDE] rounded-lg py-3 px-4'>
                        <input id="postpaid" type="radio" value="postpaid" name="type" checked={type === "postpaid"}  onChange={(e) =>toggleType(e.target.value)}
                            className="w-4 h-4 cursor-pointer" />
                           <label for="postpaid" className='text-[#010918] font-Lota400 text-sm'>Postpaid</label> 
                        </div>
                        
                    </div>

                </div>

                <div className='bg-[#ECEDEF] w-full h-[1px] mt-4'></div>

                <div className='grid grid-cols-2 gap-6 py-6 px-6'>

                     <div>
                   
                     

                    <Formik
                    onSubmit={(values, { setSubmitting }) =>
                    handleSingleElectricity(values, setSubmitting)
                    }
                    validationSchema={singleElectricityValidator}
                    initialValues={{
                    meterNumber: '',
                    amount: '',
                    phoneNumber: '',
                    email: '',
                    pin: ''
                    }}
                    >
                    {({
                    handleChange,
                    isSubmitting,
                    handleSubmit,
                    handleBlur,
                    values,
                    touched,
                    errors,
                    }) => (
                    <Form className="" onSubmit={handleSubmit}>
                         <div className='w-full rounded-[10px] shadow-card2 pt-8 pb-16 px-4'>
                        
                            <div className='flex gap-4 items-center'>
                                <div className="flex-1">
                                    <label htmlFor='meterNumber' className="block mb-2 text-[#000000] text-sm font-Lota400'">
                                        Meter Number
                                    </label>
                                    <div className="relative">
                                        <input
                                        type="text"
                                        name="meterNumber"
                                        placeholder="08000000000"
                                        className={
                                            touched.meterNumber && errors.meterNumber
                                            ? 'appearance-none w-full font-Graphix400 placeholder:text-[#96A397] placeholder:text-sm  text-[#8896AB] text-sm focus:border-error focus:outline-none rounded-lg border border-error bg-white py-4 px-4'
                                            : 'appearance-none w-full font-Graphix400 placeholder:text-[#96A397] placeholder:text-sm text-[#8896AB] text-sm focus:border-[#D5DAE1] focus:outline-none shadow-input focus:shadow-input rounded-lg border border-[#D5DAE1] bg-white py-4 px-4'
                                        }
                                        value={values.meterNumber}
                                        onChange={(e) => {
                                            handleChange(
                                              e,
                                              setSingleMeterNumber(e.target.value),
                                            )
                                          }}
                                        onBlur={handleBlur}
                                    
                                        />
                                    </div>
                                    {touched.meterNumber && errors.meterNumber ? (
                                        <small className="text-[#FF002A] font-[300] text-[10px] pt-[4px]">
                                            {touched.meterNumber && errors.meterNumber}
                                        </small>
                                    ) : null}
                                </div>

                                <div className="flex-1">
                                    <label htmlFor='amount' className="block mb-2 text-[#000000] text-sm font-Lota400'">
                                        Amount
                                    </label>
                                    <div className="relative">
                                        <input
                                        type="text"
                                        name="amount"
                                        placeholder="NGN 0.00"
                                         className={
                                            touched.amount && errors.amount
                                            ? 'appearance-none w-full font-Graphix400 placeholder:text-[#96A397] placeholder:text-sm  text-[#8896AB] text-sm focus:border-error focus:outline-none rounded-lg border border-error bg-white py-4 px-4'
                                            : 'appearance-none w-full font-Graphix400 placeholder:text-[#96A397] placeholder:text-sm text-[#8896AB] text-sm focus:border-[#D5DAE1] focus:outline-none shadow-input focus:shadow-input rounded-lg border border-[#D5DAE1] bg-white py-4 px-4'
                                        }
                                        value={values.amount}
                                        onChange={(e) => {
                                            handleChange(
                                              e,
                                              setSingleAmount(e.target.value),
                                            )
                                          }}
                                        onBlur={handleBlur}
                                        />
                                    </div>
                                    {touched.amount && errors.amount ? (
                                        <small className="text-[#FF002A] font-[300] text-[10px] pt-[4px]">
                                            {touched.amount && errors.amount}
                                        </small>
                                    ) : null}
                                 </div>

                            </div>

                            <div className='flex gap-4 items-center mt-5'>
                                <div className="flex-1">
                                    <label htmlFor='phoneNumber' className="block mb-2 text-[#000000] text-sm font-Lota400'">
                                         Phone Number
                                    </label>
                                    <div className="relative">
                                        <input
                                        type="text"
                                        name="phoneNumber"
                                        placeholder="08000000000"
                                        className={
                                            touched.phoneNumber && errors.phoneNumber
                                            ? 'appearance-none w-full font-Graphix400 placeholder:text-[#96A397] placeholder:text-sm  text-[#8896AB] text-sm focus:border-error focus:outline-none rounded-lg border border-error bg-white py-4 px-4'
                                            : 'appearance-none w-full font-Graphix400 placeholder:text-[#96A397] placeholder:text-sm text-[#8896AB] text-sm focus:border-[#D5DAE1] focus:outline-none shadow-input focus:shadow-input rounded-lg border border-[#D5DAE1] bg-white py-4 px-4'
                                        }
                                        value={values.phoneNumber}
                                        onChange={(e) => {
                                            handleChange(
                                              e,
                                              setSinglePhoneNumber(e.target.value),
                                            )
                                          }}
                                        onBlur={handleBlur}
                                    
                                        />
                                    </div>
                                    {touched.phoneNumber && errors.phoneNumber ? (
                                        <small className="text-[#FF002A] font-[300] text-[10px] pt-[4px]">
                                            {touched.phoneNumber && errors.phoneNumber}
                                        </small>
                                    ) : null}
                                </div>

                                <div className="flex-1">
                                    <label htmlFor='email' className="block mb-2 text-[#000000] text-sm font-Lota400'">
                                     Email Address
                                    </label>
                                    <div className="relative">
                                        <input
                                        type="email"
                                        name="email"
                                        placeholder="Enter your email address"
                                         className={
                                            touched.email && errors.email
                                            ? 'appearance-none w-full font-Graphix400 placeholder:text-[#96A397] placeholder:text-sm  text-[#8896AB] text-sm focus:border-error focus:outline-none rounded-lg border border-error bg-white py-4 px-4'
                                            : 'appearance-none w-full font-Graphix400 placeholder:text-[#96A397] placeholder:text-sm text-[#8896AB] text-sm focus:border-[#D5DAE1] focus:outline-none shadow-input focus:shadow-input rounded-lg border border-[#D5DAE1] bg-white py-4 px-4'
                                        }
                                        value={values.email}
                                        onChange={(e) => {
                                            handleChange(
                                              e,
                                              setSingleEmail(e.target.value),
                                            )
                                          }}
                                        onBlur={handleBlur}
                                        />
                                    </div>
                                    {touched.email && errors.email ? (
                                        <small className="text-[#FF002A] font-[300] text-[10px] pt-[4px]">
                                            {touched.email && errors.email}
                                        </small>
                                    ) : null}
                                 </div>

                            </div>
                            

                            <div className='flex justify-between items-center mt-6'>
                                <div>
                                    <p className='text-[#000000] block mb-2 text-sm font-Lota400'>
                                        Schedule Payment
                                    </p>
                                </div>
                                
                                <div>
                                <label class="relative inline-flex items-center cursor-pointer">
                                    <input type="checkbox" value=""
                                     checked={checkedSingle}
                                     onChange={(e) =>ToggleScheduleSingle(e.target.checked)}
                                     className="sr-only peer" />
                                    <div className="w-11 h-6 bg-[#CECEDE] peer-focus:outline-none  rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-[#0A49BA]"></div>
                                </label>
                                </div>
                                
                            </div>

                            <div className="mt-6">
                                <label htmlFor='pin' className="block mb-2 text-[#333F51] text-sm font-Lota400'">
                                    Pin
                                </label>
                                <div className="relative">
                                    <input
                                    type="password"
                                    name="pin"
                                    placeholder="****"
                                    className={
                                        touched.pin && errors.pin
                                        ? 'appearance-none w-full font-Graphix400 placeholder:text-[#96A397] placeholder:text-sm  text-[#8896AB] text-sm focus:border-error focus:outline-none rounded-lg border border-error bg-white py-4 px-4'
                                        : 'appearance-none w-full font-Graphix400 placeholder:text-[#96A397] placeholder:text-sm text-[#8896AB] text-sm focus:border-[#D5DAE1] focus:outline-none shadow-input focus:shadow-input rounded-lg border border-[#D5DAE1] bg-white py-4 px-4'
                                    }
                                    value={values.pin}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    
                                    />
                                </div>
                                {touched.pin && errors.pin ? (
                                    <small className="text-[#FF002A] font-[300] text-[10px] pt-[4px]">
                                        {touched.pin && errors.pin}
                                    </small>
                                ) : null}
                            </div>

                           {!isSetPin && <div className='mt-2 flex justify-end'>
                                <p className='text-[#000000] text-xs font-Lota400'>Don't have pin yet? <span onClick={() => setShowPinModal(true)} className='ml-2 cursor-pointer text-[#06D2DD]'>Setup pin</span></p>
                            </div>
                            }

                        <div className='mt-16'>
                            <button
                                    type="submit"
                                    disabled={loader}
                                    className="bg-[#0A49BA] 
                                    disabled:bg-[#0A49BA] 
                                    disabled:opacity-[0.7]
                                    text-sm
                                    font-Lota400
                                    rounded-[5px] w-full py-4 px-8 text-[#F0FDF4] 
                                        hover:bg-[#0A49BA] hover:opacity-[0.9] transition-all"
                                >   
                                    Proceed
                                </button>
                        </div>
                            

                        </div>
                            
                        </Form>
                        )}
                    </Formik>
                   
                      {type === "Bulk" && 
                      <div className='mt-6 bg-[#F1F4FC] py-8 px-6 rounded-[10px] shadow-card2'>
                            <h6 className='text-[#010918] font-Lota600 text-base'>Bulk Instructions</h6>
                            <ol className='list-decimal mt-4 ml-4'>
                                <li className='text-[#010918] font-Lota400 text-sm mb-2'>
                                Click On download sample file to get the tooltips on how to download the CSV file.
                                </li>
                                <li className='text-[#010918] font-Lota400 text-sm mb-2'>
                                Click On download sample file to get the tooltips on how to download the CSV file.
                                </li>
                                <li className='text-[#010918] font-Lota400 text-sm mb-2'>
                                Click On download sample file to get the tooltips on how to download the CSV file.
                                </li>
                                <li className='text-[#010918] font-Lota400 text-sm'>
                                Click On download sample file to get the tooltips on how to download the CSV file.
                                </li>
                            </ol>

                               <div className='mt-6 flex justify-center'>
                                    <button
                                        type="submit"
                                        disabled={false}
                                        className="bg-[#0041B1] 
                                        disabled:bg-[#0041B1] 
                                        disabled:opacity-[0.7]
                                        text-sm
                                        font-Lota600 justify-center
                                        flex items-center gap-2
                                        rounded-[5px] py-4 px-8 text-[#F0FDF4] 
                                        font-medium hover:bg-[#0041B1] hover:opacity-[0.9] transition-all"
                                    >   
                                        <img src={download_icon} alt="upload icon" className='w-4 h-4' />
                                        Download Sample Template
                                </button>
                            </div>
                            <div className='mt-2 flex justify-center'>
                                <button
                                    type="submit"
                                    disabled={false}
                                    className="
                                    text-sm
                                    font-Lota600 justify-center
                                    flex items-center gap-2
                                    rounded-[5px] py-4 px-8 text-[#0A49BA] 
                                    font-medium transition-all"
                                >   
                                    <img src={upload_icon} alt="upload icon" className='w-4 h-4' />
                                    Upload CSV File
                        </button> 
                            </div>
                        </div>
                      }
                        
                    </div>
                   
                    <div>
                 
                     <div className='w-full rounded-[10px] shadow-card2 py-8 px-6 h-fit'>
                            <div className=''>
                                <h6 className='text-[#010918] font-Lota600 text-sm'>Summary</h6>
                            </div>
                            <div className='mt-6'>
                                <div className='flex justify-between items-center'>
                                    <p className='text-[#010918] font-Lota400 text-sm'>Meter Number:</p>
                                    <p className='text-[#010918] font-Lota300 text-sm'>{singleMeterNumber === "" ? "XXXX-XXXX-XX" : singleMeterNumber}</p>
                                </div>
                                <div className='flex justify-between items-center mt-4'>
                                    <p className='text-[#010918] font-Lota400 text-sm'>Provider:</p>
                                    <p className='text-[#010918] font-Lota300 text-sm'>{providerName}</p>
                                </div>
                                <div className='flex justify-between items-center mt-4'>
                                    <p className='text-[#010918] font-Lota400 text-sm'>Amount:</p>
                                    <p className='text-[#010918] font-Lota300 text-sm'>NGN {singleAmount === "" ? 0 : singleAmount}</p>
                                </div>
                                <div className='flex justify-between items-center mt-4'>
                                    <p className='text-[#010918] font-Lota400 text-sm'>Phone Number:</p>
                                    <p className='text-[#010918] font-Lota300 text-sm'>{singlePhoneNumber === "" ? "XXXX-XXXX-XX" : `234${singlePhoneNumber.substring(1)}`}</p>
                                </div>
                                <div className='flex justify-between items-center mt-4'>
                                    <p className='text-[#010918] font-Lota400 text-sm'>Email Address:</p>
                                    <p className='text-[#010918] font-Lota300 text-sm'>{singleEmail === "" ? "Email Address" : singleEmail}</p>
                                </div>
                                <div className='flex justify-between items-center mt-4'>
                                    <p className='text-[#010918] font-Lota400 text-sm'>Recurring:</p>
                                    <p className='text-[#010918] font-Lota300 text-sm'>{checkedSingle ? 'Enabled' : 'Not Enabled'}</p>
                                </div>
                                {
                                    checkedSingle &&
                                    <>
                                    <div className='flex justify-between items-center mt-4'>
                                        <p className='text-[#010918] font-Lota400 text-sm'>Period:</p>
                                        <p className='text-[#010918] font-Lota300 text-sm'>{singlePeriod}</p>
                                    </div>
                                    <div className='flex justify-between items-center mt-4'>
                                        <p className='text-[#010918] font-Lota400 text-sm'>Start Date:</p>
                                        <p className='text-[#010918] font-Lota300 text-sm'>{singleStartDate}</p>
                                    </div>
                                    <div className='flex justify-between items-center mt-4'>
                                        <p className='text-[#010918] font-Lota400 text-sm'>End Date:</p>
                                        <p className='text-[#010918] font-Lota300 text-sm'>{singleEndDate}</p>
                                    </div>
                                </>
                             }
                            </div>
                           
                        </div>
                            
                        
                    {type === "Bulk" && 
                    <div className='w-full rounded-[10px] shadow-card2 py-8 px-6 h-fit mt-6'>
                             <div className=''>
                                <h6 className='text-[#010918] font-Lota600 text-sm'>Summary</h6>
                             </div>
                             <div className='mt-6 mb-6'>
                                <div className='flex justify-between items-center'>
                                    <p className='text-[#010918] font-Lota400 text-sm'>Total Numbers:</p>
                                    <p className='text-[#010918] font-Lota300 text-sm'>13 Numbers</p>
                                </div>
                                <div className='flex justify-between  mt-4'>
                                    <div>
                                         <p className='text-[#010918] font-Lota400 text-sm'>Total Operators:</p>
                                    </div>
                                    <div className='text-right'>
                                      <p className='text-[#010918] font-Lota300 text-sm'>3 MTN, Nigeria</p>
                                      <p className='text-[#010918] font-Lota300 text-sm mt-1'>4 GLO,Nigeria</p>
                                      <p className='text-[#010918] font-Lota300 text-sm mt-1'>6 Airtel,Nigeria</p>
                                    </div>    
                                </div>
                            </div>

                            <div className='mt-6 bg-[#EDEFF5] flex items-center justify-between rounded-md py-5 px-4'>
                                 <p className='text-[#010918] text-sm font-Lota400'>Total Payment Amount:</p>
                                <p className='text-[#010918] font-Lota300 text-sm'>NGN9,000</p>
                            </div>

                            <div className='flex justify-between items-center mt-6'>
                                <div>
                                    <p className='text-[#000000] block mb-2 text-sm font-Lota400'>
                                        Schedule Bulk Data
                                    </p>
                                </div>
                                
                                <div>
                                  <label class="relative inline-flex items-center cursor-pointer">
                                        <input type="checkbox" 
                                        checked={checkedBulk}
                                        value="" className="sr-only peer" 
                                        onChange={(e) =>ToggleScheduleBulk(e.target.checked)} />
                                     <div className="w-11 h-6 bg-[#CECEDE] peer-focus:outline-none  rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-[#0A49BA]"></div>
                                 </label>
                                </div>
                                
                            </div>

                            <div className='mt-10 text-center'>
                                <button
                                onClick={processBulkAirtime}
                                    type="submit"
                                    disabled={false}
                                    className="bg-[#0041B1] 
                                    disabled:bg-[#0041B1] 
                                    disabled:opacity-[0.7]
                                    text-sm
                                    font-Graphix500 min-w-[300px]
                                    rounded-lg py-4 px-8 text-[#F0FDF4]
                                    hover:bg-[#0041B1] hover:opacity-[0.9] transition-all"
                                >
                                Proceed
                            </button>
                            </div>
                            <div className='text-center mt-4'>
                            <button
                            className='text-[#898CA6] font-Lota400 text-sm'
                            >Go Back</button>
                            </div>


                        </div>
                        }
                    </div>

                </div>

             </div>
        </>
     );
}
 
export default ElectricityTopUpPage;