import React from 'react'
import chevron_right from '../../assets/icons/chevron_right.svg'
import gotv from '../../assets/images/gotv.svg'
import dstv from '../../assets/images/dstv.svg'
import startimes from '../../assets/images/startimes.svg'
import { Link } from 'react-router-dom'

const CablesPage = () => {
    return ( 
        <>
             <div className='mt-5 md:mt-10 bg-[#FFFFFF] border border-[#D8D8D8] rounded-xl py-8 px-6'>
                  <div className='grid md:grid-cols-3 gap-6'>
                        <div className='shadow-card2 rounded-[10px]'>
                            <div className='flex flex-col justify-center items-center min-h-[220px]'>
                                <img src={dstv} alt="dstv logo" />
                            </div>
                            <div className='flex border-t-[0.45px] border-[#D8D8D8] items-center py-3 px-4 justify-between'>
                                <div>
                                    <p className='text-[#0041B1] text-xs font-Lota400'>Pay your DSTV Subscription </p>
                                </div>
                                <Link to={`/paybills/dstv-subscription`} className='bg-[#F0F0F0] cursor-pointer w-[30px] h-[30px] rounded-full flex justify-center items-center border-[0.54px] border-[#D8D8D8]'>
                                    <img src={chevron_right} alt="chevron right icon" />
                                </Link>
                            </div>
                        </div>

                        <div className='shadow-card2 rounded-[10px]'>
                            <div className='flex flex-col justify-center items-center min-h-[220px]'>
                                <img src={gotv} alt="gotv logo" />
                            </div>
                            <div className='flex border-t-[0.45px] border-[#D8D8D8] items-center py-3 px-4 justify-between'>
                                <div>
                                    <p className='text-[#0041B1] text-xs font-Lota400'>Pay your GOTV subscription </p>
                                </div>
                                <Link to="/paybills/gotv-subscription" className='bg-[#F0F0F0] cursor-pointer w-[30px] h-[30px] rounded-full flex justify-center items-center border-[0.54px] border-[#D8D8D8]'>
                                    <img src={chevron_right} alt="chevron right icon" />
                                </Link>
                            </div>
                        </div>

                        <div className='shadow-card2 rounded-[10px]'>
                            <div className='flex flex-col justify-center items-center min-h-[220px]'>
                                <img src={startimes} alt="startimes logo" />
                            </div>
                            <div className='flex border-t-[0.45px] border-[#D8D8D8] items-center py-3 px-4 justify-between'>
                                <div>
                                    <p className='text-[#0041B1] text-xs font-Lota400'>Pay your STARTIMES subscription</p>
                                </div>
                                <Link to="/paybills/startimes-subscription" className='bg-[#F0F0F0] cursor-pointer w-[30px] h-[30px] rounded-full flex justify-center items-center border-[0.54px] border-[#D8D8D8]'>
                                    <img src={chevron_right} alt="chevron right icon" />
                                </Link>
                            </div>
                        </div>

                         
                 </div>
             </div>
        </>
     );
}
 
export default CablesPage;