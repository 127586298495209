import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import {apiUrl} from '../config'
import cogoToast from "cogo-toast"


const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};


export const createSingleAirtime = createAsyncThunk(
    'createSingleAirtime',
    async ({amount, mobileNumber, isReccurring, scheduledTime, reccurringType, startDate, endDate, pin}, {rejectWithValue, dispatch}) => {
      try {
        if(isReccurring){
          const res = await axios.post(
            `${apiUrl}single-airtime/instruction`,
            {
                  amount: parseFloat(amount),
                  mobileNumber: mobileNumber,
                  isReccurring: isReccurring,
                  scheduledTime: scheduledTime,
                  reccurringType: reccurringType,
                  startDate : startDate,
                  endDate: endDate,
                  pin: pin
            },
            {
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `${getToken()}`
              }, 
            }
          )
          if(res.status === 200){
            cogoToast.success("Your request has been processed successfully");
              return res.data
          }
        }
        else{
          const res = await axios.post(
            `${apiUrl}single-airtime/instruction`,
            {
                  amount: parseFloat(amount),
                  mobileNumber: mobileNumber,
                  isReccurring: isReccurring,
                  scheduledTime: scheduledTime,
                  pin: pin
            },
            {
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `${getToken()}`
              }, 
            }
          )
          if(res.status === 200){
            cogoToast.success("Your request has been processed successfully");
              return res.data
          }
        }
      
      } catch (error) {
        console.log(error.response)
        // return custom error message from API if any
        if(error.response.status === 400){
          cogoToast.error(error.response.data.message)
          return rejectWithValue(error.response)
        }
        else if(error.response.status === 401){
          dispatch({type: 'auth/Logout'})
        }
        else{
          return rejectWithValue(error.response)
        }
      }
    }
  )

export const createSingleData = createAsyncThunk(
  'createSingleData',
  async ({amount, mobileNumber, mobileNetwork, isReccurring,reccurringType, scheduledTime, pin, sku}, {rejectWithValue, dispatch}) => {
    try {
      if(isReccurring){
        const res = await axios.post(
          `${apiUrl}single-data-sub/instruction`,
          {
                amount: parseFloat(amount),
                mobileNumber: mobileNumber,
                mobileNetwork: mobileNetwork,
                isReccurring: isReccurring,
                scheduledTime: scheduledTime,
                reccurringType: reccurringType,
                pin: pin,
                sku: sku
          },
          {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${getToken()}`
            }, 
          }
        )
        if(res.status === 200){
          cogoToast.success("Your request has been processed successfully");
            return res.data
        }
      }
      else{
        const res = await axios.post(
          `${apiUrl}single-data-sub/instruction`,
          {
                amount: parseFloat(amount),
                mobileNumber: mobileNumber,
                isReccurring: isReccurring,
                mobileNetwork:mobileNetwork,
                scheduledTime: scheduledTime,
                pin: pin,
                sku: sku
          },
          {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${getToken()}`
            }, 
          }
        )
        if(res.status === 200){
          cogoToast.success("Your request has been processed successfully");
            return res.data
        }
      }
    
    } catch (error) {
      console.log(error.response)
      // return custom error message from API if any
      if(error.response.status === 400){
        cogoToast.error(error.response.data.message)
        return rejectWithValue(error.response)
      }
      else if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const fetchDataBundles = createAsyncThunk(
  'fetchDataBundles',
  async ({phoneNumber}, {rejectWithValue, dispatch, getState}) => {
    try {
      const res = await axios.get(
        `${apiUrl}single-data-sub/packages/${phoneNumber}`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data.data
      }
      
    } catch (error) {
      // return custom error message from API if any
      if(error.response.status === 400 || error.response.status  === 403){
        return rejectWithValue(error.response)
      }
      else if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)