import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import {apiUrl} from '../config'
import cogoToast from "cogo-toast"


const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};


export const createSingleElectricity = createAsyncThunk(
    'createSingleElectricity',
    async ({amount,cardNumber,providerName,isPrepaid, sku,receiverEmail,receiverPhone, isReccurring, scheduledTime, reccurringType, startDate, endDate, pin}, {rejectWithValue, dispatch}) => {
      try {
        if(isReccurring){
          const res = await axios.post(
            `${apiUrl}single-bill/instruction`,
            {
                  amount: parseFloat(amount),
                  cardNumber: cardNumber,
                  providerName: providerName,
                  isReccurring: isReccurring,
                  isPrepaid: isPrepaid,
                  billType: "ELECTRICITY",
                  sku: sku,
                  receiverPhone: receiverPhone,
                  receiverEmail: receiverEmail,
                  scheduledTime: scheduledTime,
                  reccurringType: reccurringType,
                  startDate : startDate,
                  endDate: endDate,
                  pin: pin
            },
            {
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `${getToken()}`
              }, 
            }
          )
          if(res.status === 200){
            cogoToast.success("Your request has been processed successfully");
              return res.data
          }
        }
        else{
          const res = await axios.post(
            `${apiUrl}single-bill/instruction`,
            {
                  amount: parseFloat(amount),
                  cardNumber: cardNumber,
                  providerName: providerName,
                  isPrepaid: isPrepaid,
                  billType: "ELECTRICITY",
                  sku: sku,
                  receiverPhone: receiverPhone,
                  receiverEmail: receiverEmail,
                  isReccurring: isReccurring,
                  scheduledTime: scheduledTime,
                  pin: pin
            },
            {
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `${getToken()}`
              }, 
            }
          )
          if(res.status === 200){
            cogoToast.success("Your request has been processed successfully");
              return res.data
          }
        }
      
      } catch (error) {
        console.log(error.response)
        // return custom error message from API if any
        if(error.response.status === 400){
          cogoToast.error(error.response.data.message)
          return rejectWithValue(error.response)
        }
        else if(error.response.status === 401){
          dispatch({type: 'auth/Logout'})
        }
        else{
          return rejectWithValue(error.response)
        }
      }
    }
  )


export const fetchElectricityProducts = createAsyncThunk(
  'fetchElectricityProducts',
  async (arg, {rejectWithValue, dispatch, getState}) => {
    try {
      const res = await axios.get(
        `${apiUrl}single-bill/product/NG/electricity`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data.data
      }
      
    } catch (error) {
      // return custom error message from API if any
      if(error.response.status === 400 || error.response.status  === 403){
        return rejectWithValue(error.response)
      }
      else if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const fetchInternetServices = createAsyncThunk(
  'fetchInternetServices',
  async (arg, {rejectWithValue, dispatch, getState}) => {
    try {
      const res = await axios.get(
        `${apiUrl}single-bill/product/NG/internet`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data.data
      }
      
    } catch (error) {
      // return custom error message from API if any
      if(error.response.status === 400 || error.response.status  === 403){
        return rejectWithValue(error.response)
      }
      else if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const fetchCableBundles = createAsyncThunk(
  'fetchCableBundles',
  async ({cableNumber}, {rejectWithValue, dispatch, getState}) => {
    try {
      const res = await axios.get(
        `${apiUrl}single-bill/dstv/${cableNumber}`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data.data
      }
      
    } catch (error) {
      // return custom error message from API if any
      if(error.response.status === 400 || error.response.status  === 403){
        return rejectWithValue(error.response)
      }
      else if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const createSingleCable = createAsyncThunk(
    'createSingleCable',
    async ({amount,cardNumber,providerName, sku,receiverEmail,productCode,receiverPhone, isReccurring, scheduledTime, reccurringType, startDate, endDate, pin}, {rejectWithValue, dispatch}) => {
      try {
        if(isReccurring){
          const res = await axios.post(
            `${apiUrl}single-bill/instruction`,
            {
                  amount: parseFloat(amount),
                  cardNumber: cardNumber,
                  providerName: providerName,
                  isReccurring: isReccurring,
                  billType: "CABLE",
                  sku: sku,
                  receiverPhone: receiverPhone,
                  receiverEmail: receiverEmail,
                  scheduledTime: scheduledTime,
                  reccurringType: reccurringType,
                  startDate : startDate,
                  endDate: endDate,
                  pin: pin
            },
            {
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `${getToken()}`
              }, 
            }
          )
          if(res.status === 200){
            cogoToast.success("Your request has been processed successfully");
              return res.data
          }
        }
        else{
          const res = await axios.post(
            `${apiUrl}single-bill/instruction`,
            {
                  amount: parseFloat(amount),
                  cardNumber: cardNumber,
                  providerName: providerName,
                  billType: "CABLE",
                  sku: sku,
                  receiverPhone: receiverPhone,
                  receiverEmail: receiverEmail,
                  isReccurring: isReccurring,
                  scheduledTime: scheduledTime,
                  pin: pin
            },
            {
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `${getToken()}`
              }, 
            }
          )
          if(res.status === 200){
            cogoToast.success("Your request has been processed successfully");
              return res.data
          }
        }
      
      } catch (error) {
        console.log(error.response)
        // return custom error message from API if any
        if(error.response.status === 400){
          cogoToast.error(error.response.data.message)
          return rejectWithValue(error.response)
        }
        else if(error.response.status === 401){
          dispatch({type: 'auth/Logout'})
        }
        else{
          return rejectWithValue(error.response)
        }
      }
    }
  )

  export const fetchInternetBundles = createAsyncThunk(
    'fetchInternetBundles',
    async ({product}, {rejectWithValue, dispatch, getState}) => {
      try {
        const res = await axios.get(
          `${apiUrl}single-bill/internet/${product}`,
          {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${getToken()}`
            }, 
          }
        )
        if(res.status === 200){
            return res.data.data
        }
        
      } catch (error) {
        // return custom error message from API if any
        if(error.response.status === 400 || error.response.status  === 403){
          return rejectWithValue(error.response)
        }
        else if(error.response.status === 401){
          dispatch({type: 'auth/Logout'})
        }
        else{
          return rejectWithValue(error.response)
        }
      }
    }
  )

  export const createSingleInternet = createAsyncThunk(
    'createSingleInternet',
    async ({amount,cardNumber,providerName, sku,receiverEmail,productCode,receiverPhone, isReccurring, scheduledTime, reccurringType, startDate, endDate, pin}, {rejectWithValue, dispatch}) => {
      try {
        if(isReccurring){
          const res = await axios.post(
            `${apiUrl}single-bill/instruction`,
            {
                  amount: parseFloat(amount),
                  cardNumber: cardNumber,
                  providerName: providerName,
                  isReccurring: isReccurring,
                  billType: "INTERNET",
                  sku: sku,
                  productCode: productCode,
                  receiverPhone: receiverPhone,
                  receiverEmail: receiverEmail,
                  scheduledTime: scheduledTime,
                  reccurringType: reccurringType,
                  startDate : startDate,
                  endDate: endDate,
                  pin: pin
            },
            {
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `${getToken()}`
              }, 
            }
          )
          if(res.status === 200){
            cogoToast.success("Your request has been processed successfully");
              return res.data
          }
        }
        else{
          const res = await axios.post(
            `${apiUrl}single-bill/instruction`,
            {
                  amount: parseFloat(amount),
                  cardNumber: cardNumber,
                  providerName: providerName,
                  billType: "INTERNET",
                  sku: sku,
                  productCode: productCode,
                  receiverPhone: receiverPhone,
                  receiverEmail: receiverEmail,
                  isReccurring: isReccurring,
                  scheduledTime: scheduledTime,
                  pin: pin
            },
            {
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `${getToken()}`
              }, 
            }
          )
          if(res.status === 200){
            cogoToast.success("Your request has been processed successfully");
              return res.data
          }
        }
      
      } catch (error) {
        console.log(error.response)
        // return custom error message from API if any
        if(error.response.status === 400){
          cogoToast.error(error.response.data.message)
          return rejectWithValue(error.response)
        }
        else if(error.response.status === 401){
          dispatch({type: 'auth/Logout'})
        }
        else{
          return rejectWithValue(error.response)
        }
      }
    }
  )
