import React,{useState,useEffect} from 'react'
import logo_full from '../../assets/icons/logo_full.svg'
import logo from '../../assets/icons/logo_white.svg'
import eye from '../../assets/icons/eye.svg'
import eye_off from '../../assets/icons/eye_off.svg'
import checkbox_white from '../../assets/icons/checkbox_white.svg'
// import arrow_down from '../../assets/icons/arrow_down.svg'
import { Form, Formik } from 'formik'
import { registerValidator } from '../../validationSchema/validator'
import { Link,useNavigate } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useDispatch, useSelector } from 'react-redux'
import { userRegister } from '../../redux/Auth/authActions'
import { clearSignUpSuccess } from '../../redux/Auth/auth'

const RegisterPage = () => {    
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {loading,signup_success} = useSelector((state) => state.auth);

    const [passwordShown, setPasswordShown] = useState(false)
    const [phone, setPhone] = useState("")
    const [valid, setValid] = useState(false)
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true)
    }

    const handleSubmit = (values) => {
        console.log(values)
        if(phone === ''){
            setValid(true)
        }
        else{
            setValid(false)
            let res = {
                ...values, 
                phoneNumber: phone
            }
            console.log(res)
            dispatch(userRegister(res))
        }
    }

    useEffect(()=>{
        if(signup_success){
            navigate('/auth/email/verify')
            setTimeout(()=>{
                dispatch(clearSignUpSuccess())
            },1000)
        }   
    },[dispatch,navigate, signup_success])

    return ( 
        <>
             <div className='px-5 md:px-16 py-6 authbg font-poppins'>
            
            <div className=''>
                <img src={logo_full} className="w-[150px]" alt="logo" />
            </div>

            <div className='grid md:grid-cols-2 items-center gap-8 mt-20'>
                <div className='hidden md:block'>
                    <div className='flex w-fit bg-[#0041B1] rounded-[36px]'>
                        <p className='text-[#DCFCE7] font-medium text-xs py-1 px-3'>Payments</p>   
                    </div>
                    <div className='mt-6'>
                        <h4 className='text-[#2A3342] font-Lota600 md:text-4xl text-3xl max-w-md md:max-w-lg tracking-wide'>
                        Carefully built to allow you carry bill payments seamlessly.
                        </h4>
                    </div>
                    <div className='mt-8'>
                        <p className='text-[#556987] text-sm font-Graphix400'>Lorem ipsum dolor sit amet, consectetur adipisng.</p>
                    </div>

                    <div className='flex gap-3 items-center mt-6 mb-3'>
                            <div className='bg-[#0041B1] flex justify-center items-center w-[20px] h-[20px] rounded-full'>
                            <img src={checkbox_white} className='' alt="checkbox" />
                        </div>
                        <div>
                    <p className='text-[#556987] text-sm font-Graphix400'>Mauris pellentesque congue libero nec</p>
                        </div>
                    </div>

                    <div className='flex gap-3 items-center mb-3'>
                            <div className='bg-[#0041B1] flex justify-center items-center w-[20px] h-[20px] rounded-full'>
                            <img src={checkbox_white} alt="checkbox" />
                        </div>
                        <div>
                    <p className='text-[#556987] text-sm font-Graphix400'>Suspendisse mollis tincidunt</p>
                        </div>
                    </div>

                    <div className='flex gap-3 items-center mb-3'>
                            <div className='bg-[#0041B1] flex justify-center items-center w-[20px] h-[20px] rounded-full'>
                            <img src={checkbox_white} alt="checkbox" />
                        </div>
                        <div>
                    <p className='text-[#556987] text-sm font-Graphix400'>Praesent varius justo vel justo pulvinar </p>
                        </div>
                    </div>

                </div>
                <div className='bg-white shadow-card relative rounded-lg md:rounded-[16px] py-8 px-6 md:px-16 max-w-lg md:max-w-xl'>

                    <div className='absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                        <div className='bg-[#0041B1] rounded-[12px] w-[55px] h-[55px] flex justify-center items-center'>
                            <img src={logo} className="w-[30px] h-[30px]" alt="logo" />
                        </div>
                    </div>
                    

                    <div className='text-center mt-4'>
                        <h4 className='text-[#2A3342] font-Lota600 md:text-2xl text-xl'>Create Account</h4>
                        <h6 className='text-[#556987] font-Graphix400 mt-4'>We're happy you're here, lets set you up.</h6>
                    </div>

                    {/* form */}
                    <Formik
                    onSubmit={(values, { setSubmitting }) =>
                    handleSubmit(values, setSubmitting)
                    }
                    validationSchema={registerValidator}
                    initialValues={{
                    email: '',
                    password: '',
                    firstName: '',
                    lastName: '',
                    }}
                >
                    {({
                    handleChange,
                    isSubmitting,
                    handleSubmit,
                    handleBlur,
                    values,
                    touched,
                    errors,
                    }) => (
                    <Form className="mt-6" onSubmit={handleSubmit}>
                        
                        <div className=''>
                        <label htmlFor='firstName' className="block mb-2 text-[#333F51] text-sm font-Graphix500">
                            First Name
                        </label>
                        <input
                            type="text"
                            name="firstName"
                            placeholder="Enter your first name"
                            className={
                            touched.firstName && errors.firstName
                                ? 'appearance-none w-full font-Graphix400 placeholder:text-[#8896AB] placeholder:text-sm  text-[#8896AB] text-sm focus:border-error focus:outline-none rounded-lg border border-error bg-white py-4 px-4'
                                : 'appearance-none w-full font-Graphix400 placeholder:text-[#8896AB] placeholder:text-sm text-[#8896AB] text-sm focus:border-[#D5DAE1] shadow-input  focus:outline-none focus:shadow-input rounded-lg border border-[#D5DAE1] bg-white py-4 px-4'
                            }
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {touched.firstName && errors.firstName ? (
                            <small className="text-[#FF002A] font-[300] text-[10px] pt-[4px]">
                                {touched.firstName && errors.firstName}
                            </small>
                        ) : null}
                        </div>

                        <div className='mt-5'>
                        <label htmlFor='lastName' className="block mb-2 text-[#333F51] text-sm font-Graphix500">
                            Last Name
                        </label>
                        <input
                            type="text"
                            name="lastName"
                            placeholder="Enter your last name"
                            className={
                            touched.lastName && errors.lastName
                                ? 'appearance-none w-full font-Graphix400 placeholder:text-[#8896AB] placeholder:text-sm  text-[#8896AB] text-sm focus:border-error focus:outline-none rounded-lg border border-error bg-white py-4 px-4'
                                : 'appearance-none w-full font-Graphix400 placeholder:text-[#8896AB] placeholder:text-sm text-[#8896AB] text-sm focus:border-[#D5DAE1] shadow-input  focus:outline-none focus:shadow-input rounded-lg border border-[#D5DAE1] bg-white py-4 px-4'
                            }
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {touched.lastName && errors.lastName ? (
                            <small className="text-[#FF002A] font-[300] text-[10px] pt-[4px]">
                                {touched.lastName && errors.lastName}
                            </small>
                        ) : null}
                        </div>

                        <div className='mt-5'>
                        <label htmlFor='email' className="block mb-2 text-[#333F51] text-sm font-Graphix500">
                            Email
                        </label>
                        <input
                            type="text"
                            name="email"
                            placeholder="Enter your email address"
                            className={
                            touched.email && errors.email
                                ? 'appearance-none w-full font-Graphix400 placeholder:text-[#8896AB] placeholder:text-sm  text-[#8896AB] text-sm focus:border-error focus:outline-none rounded-lg border border-error bg-white py-4 px-4'
                                : 'appearance-none w-full font-Graphix400 placeholder:text-[#8896AB] placeholder:text-sm text-[#8896AB] text-sm focus:border-[#D5DAE1] shadow-input  focus:outline-none focus:shadow-input rounded-lg border border-[#D5DAE1] bg-white py-4 px-4'
                            }
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {touched.email && errors.email ? (
                            <small className="text-[#FF002A] font-[300] text-[10px] pt-[4px]">
                                {touched.email && errors.email}
                            </small>
                        ) : null}
                        </div>


                        <div className='mt-5'>
                        <label htmlFor='phoneNumber' className="block mb-2 text-[#333F51] text-sm font-Graphix500">
                            Phone Number
                        </label>
                        {/* <input
                            type="text"
                            name="phoneNumber"
                            placeholder="Enter your phone number"
                            className={
                            touched.phoneNumber && errors.phoneNumber
                                ? 'appearance-none w-full placeholder:text-[#8896AB] placeholder:text-sm  text-[#8896AB] text-sm focus:border-error focus:outline-none rounded-lg border border-error bg-white py-4 px-4'
                                : 'appearance-none w-full placeholder:text-[#8896AB] placeholder:text-sm text-[#8896AB] text-sm focus:border-[#D5DAE1] shadow-input  focus:outline-none focus:shadow-input rounded-lg border border-[#D5DAE1] bg-white py-4 px-4 mb-4'
                            }
                            value={values.phoneNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {touched.phoneNumber && errors.phoneNumber ? (
                            <small className="text-[#FF002A] font-[300] text-[10px] pt-[4px]">
                                {touched.phoneNumber && errors.phoneNumber}
                            </small>
                        ) : null} */}

                         
                            <PhoneInput
                            country={'ng'}
                            value={phone}
                            onChange={
                                (phone) =>
                                {
                                    setPhone(phone)
                                    setValid(false)
                                }
                            }
                            inputStyle={{
                            border: '1px solid #D5DAE1', color: '#8896AB', width: '100%',
                            borderRadius: '8px', height: 'auto', lineHeight: '0px',
                            backgroundColor: '#fff', padding: '1rem 50px' }}
                            />
                       { valid && <small className="text-[#FF002A] font-[300] text-[10px] pt-[4px]">
                           Phone number is required
                         </small>
                            }

                        </div>

                        <div className="mt-5">
                        <label htmlFor='password' className="block mb-2 text-[#333F51] text-sm font-Graphix500">
                            Password
                        </label>
                        <div className="relative">
                            <input
                            type={passwordShown ? 'text' : 'password'}
                            name="password"
                            placeholder="Enter your password"
                            className={
                                touched.password && errors.password
                                ? 'appearance-none w-full font-Graphix400 placeholder:text-[#96A397] placeholder:text-sm  text-[#8896AB] text-sm focus:border-error focus:outline-none rounded-lg border border-error bg-white py-4 px-4'
                                : 'appearance-none w-full font-Graphix400 placeholder:text-[#96A397] placeholder:text-sm text-[#8896AB] text-sm focus:border-[#D5DAE1] focus:outline-none shadow-input focus:shadow-input rounded-lg border border-[#D5DAE1] bg-white py-4 px-4'
                            }
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            />
                            <img
                            onClick={togglePasswordVisiblity}
                            src={passwordShown ? eye_off : eye}
                            alt="eye"
                            className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                            />
                        </div>
                        {touched.password && errors.password ? (
                            <small className="text-[#FF002A] font-[300] text-[10px] pt-[4px]">
                                {touched.password && errors.password}
                            </small>
                        ) : null}
                        </div>
                          

                        <div className="mt-6">
                        <button
                            type="submit"
                            disabled={loading}
                            className="bg-[#0041B1] 
                            disabled:bg-[#0041B1] 
                            disabled:opacity-[0.7]
                            text-sm
                            font-Graphix500
                            rounded-lg w-full py-4 text-[#F0FDF4] font-medium hover:bg-[#0041B1] hover:opacity-[0.9] transition-all"
                        >
                            Sign Up
                        </button>
                        </div>

                        <div className='flex items-center gap-3 mt-8'>
                            <div className='bg-[#EEF0F3] w-full h-[1px]'></div>
                            <div>
                                    <p className='text-[#8896AB] text-sm font-medium'>OR</p>
                            </div>
                            <div className='bg-[#EEF0F3] w-full h-[1px]'></div>
                        </div>

                        <div className='text-center mt-5'>
                            <p className='text-[#8896AB] text-sm font-medium'>Already have an account? <Link to="/" className='text-[#0A49BA] font-medium text-sm'>Login</Link> </p>
                        </div>

                    
                    </Form>
                    )}
                </Formik>

                </div>
            </div>

         </div>
        </>
     );
}
 
export default RegisterPage;