import React, { useEffect } from 'react'
import chevron_right from '../../assets/icons/chevron_right.svg'
import ikejaElectric from '../../assets/images/ikejaElectric.svg'
import ekoElectric from '../../assets/images/ekoElectric.svg'
// import beninElectric from '../../assets/images/beninElectric.svg'
import portElectric from '../../assets/images/portElectric.svg'
import kadunaElectric from '../../assets/images/kadunaElectric.svg'
import ibadanElectric from '../../assets/images/ibadanElectric.svg'
import kanoElectric from '../../assets/images/kanoElectric.svg'
import josElectric from '../../assets/images/josElectric.svg'
import { useDispatch, useSelector } from 'react-redux'
import { fetchElectricityProducts } from '../../redux/Bills/billActions'
import { Link } from 'react-router-dom'

const ElectricityPage = () => {
    const dispatch = useDispatch()
    const { products, loader} = useSelector((state) => state.bill);

    // format electricty images
    const formatIcon = (val) =>{
        let res;
        switch(val){
            case 'Eko PHCN':
                res = ekoElectric
             break;
            case 'Ikeja Electric':
                res = ikejaElectric
                break;
            case 'Ibadan Distribution':
                res = ibadanElectric
                break;
            case 'Enugu Distribution':
                res = portElectric
                break;
            case 'Port Harcourt Prepaid':
                res = portElectric
                break;
            case 'Port Harcourt Postpaid':
                res = portElectric
                break;
            case 'Kano Prepaid':
                res = kanoElectric
                break;
            case 'Kano Postpaid':
                res = kanoElectric
                break;
            case 'Abuja Prepaid':
                res = portElectric
                break;
            case 'Abuja Postpaid':
                res = portElectric
                break;
            case 'Kaduna':
                res = kadunaElectric
                break;
            case 'JOS':
                res = josElectric
                break;
            default:
                break;
        }
        return res
    }

    useEffect(()=>{
        dispatch(fetchElectricityProducts())
    },[dispatch])

    return ( 
        <>
             <div className='mt-16 bg-[#FFFFFF] border border-[#D8D8D8] rounded-xl py-8 px-6'>
                  <div className='grid grid-cols-3 gap-6'>
                
                         {loader &&
                        ['2', '1', '4', '5', '3', '8'].map((item) => (
                            <div className="mb-2 bg-[#FFFFFF] shadow-card2 rounded-[10px] py-5 px-4">
                            <div className="animate-pulse w-full">
                                <div className="h-4 bg-gray-200 rounded-md  w-full mb-2"></div>
                                <div className="h-4 bg-gray-200 rounded-md  w-full mb-2"></div>
                                <div className="h-10 bg-gray-200 rounded-md  w-full mb-2"></div>
                                <div className="h-4 bg-gray-200 rounded-md mb-2 mt-5 w-full"></div>
                                <div className="h-4 bg-gray-200 rounded-md  w-full"></div>
                            </div>
                        </div>
                        ))}

                        {/* mapping internet products */}
                        {
                            !loader && products.length ?
                         products.map((val, index)=>(
                            <div key={index} className='shadow-card2 rounded-[10px]'>
                                <div className='flex flex-col justify-center items-center min-h-[220px]'>
                                    <img src={formatIcon(val.name)} alt="jos Electric" />
                                </div>
                                <div className='flex border-t-[0.45px] border-[#D8D8D8] items-center py-3 px-4 justify-between'>
                                    <div>
                                        <p className='text-[#0041B1] text-xs font-Lota400'>Pay your {val.name} bill</p>
                                    </div>
                                    <Link to={`/paybills/electricity/${val.product_id}`} className='bg-[#F0F0F0] cursor-pointer w-[30px] h-[30px] rounded-full flex justify-center items-center border-[0.54px] border-[#D8D8D8]'>
                                        <img src={chevron_right} alt="chevron right icon" />
                                    </Link>
                                </div>
                            </div>
                         ))
                         :
                         ""
                        }

                 </div>
             </div>
        </>
     );
}
 
export default ElectricityPage;