import React from 'react'
import bell_green from '../../assets/icons/bell_green.svg'
import close_grey from '../../assets/icons/close_grey.svg'


const NotificationPage = () => {
    return ( 
        <>
             <div className='mt-5 md:mt-10 bg-[#FFFFFF] rounded-xl py-6 px-6 h-full'>

                {/*  */}
                <div className='border-[0.98px] border-[#D8D8D8] rounded-[10px] py-8 px-6 md:px-8 mt-4'>
                    <h4 className='text-[#000000] font-Lota400 text-lg'>Today</h4>
                    <div className='flex justify-between items-center mt-6'>
                        <div className='flex gap-4'>
                            <div>
                                <img src={bell_green} alt="bell" />
                            </div>
                            <div>
                                <p className='text-[#849AC0] text-sm font-Lota400'>Your DSTV subscription will expire in three days.</p>
                                <p className='mt-1 text-[#000000] text-xs font-Lota400'>March 29, 05:13:56</p>
                            </div>

                        </div>
                        <div>
                            <img src={close_grey} alt="close icon" className='cursor-pointer' />
                        </div>
                    </div>
                     
                </div>

                <div className='border-[0.98px] border-[#D8D8D8] rounded-[10px] py-8 px-6 md:px-8 mt-5'>
                    <h4 className='text-[#000000] font-Lota400 text-lg'>Earlier</h4>
                    <div className='flex justify-between items-center mt-6'>
                        <div className='flex gap-4'>
                            <div>
                                <img src={bell_green} alt="bell" />
                            </div>
                            <div>
                                <p className='text-[#849AC0] text-sm font-Lota400'>Your DSTV subscription will expire in three days.</p>
                                <p className='mt-1 text-[#000000] text-xs font-Lota400'>March 29, 05:13:56</p>
                            </div>

                        </div>
                        <div>
                            <img src={close_grey} alt="close icon" className='cursor-pointer' />
                        </div>
                    </div>

                    <div className='flex justify-between items-center mt-8'>
                        <div className='flex gap-4'>
                            <div>
                                <img src={bell_green} alt="bell" />
                            </div>
                            <div>
                                <p className='text-[#849AC0] text-sm'>Your DSTV subscription will expire in three days.</p>
                                <p className='mt-1 text-[#000000] text-xs'>March 29, 05:13:56</p>
                            </div>

                        </div>
                        <div>
                            <img src={close_grey} alt="close icon" className='cursor-pointer' />
                        </div>
                    </div>
                    
                    <div className='flex justify-between items-center mt-8'>
                        <div className='flex gap-4'>
                            <div>
                                <img src={bell_green} alt="bell" />
                            </div>
                            <div>
                                <p className='text-[#849AC0] text-sm font-Lota400'>Your DSTV subscription will expire in three days.</p>
                                <p className='mt-1 text-[#000000] text-xs font-Lota400'>March 29, 05:13:56</p>
                            </div>

                        </div>
                        <div>
                            <img src={close_grey} alt="close icon" className='cursor-pointer' />
                        </div>
                    </div>

                    <div className='flex justify-between items-center mt-8'>
                        <div className='flex gap-4'>
                            <div>
                                <img src={bell_green} alt="bell" />
                            </div>
                            <div>
                                <p className='text-[#849AC0] text-sm font-Lota400'>Your DSTV subscription will expire in three days.</p>
                                <p className='mt-1 text-[#000000] text-xs font-Lota400'>March 29, 05:13:56</p>
                            </div>

                        </div>
                        <div>
                            <img src={close_grey} alt="close icon" className='cursor-pointer' />
                        </div>
                    </div>

                    <div className='flex justify-between items-center mt-8'>
                        <div className='flex gap-4'>
                            <div>
                                <img src={bell_green} alt="bell" />
                            </div>
                            <div>
                                <p className='text-[#849AC0] text-sm font-Lota400'>Your DSTV subscription will expire in three days.</p>
                                <p className='mt-1 text-[#000000] text-xs font-Lota400'>March 29, 05:13:56</p>
                            </div>

                        </div>
                        <div>
                            <img src={close_grey} alt="close icon" className='cursor-pointer' />
                        </div>
                    </div>
                     
                </div>


             </div>
        </>
     ); 
}
 
export default NotificationPage;