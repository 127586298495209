import React,{useState} from 'react'
import Sidebar from '../SideBar/Sidebar';
import menu_icon from '../../assets/icons/hamburger.svg'

const SideWrapper = ({children, title}) => {

    const [sideShow, setSideShow] = useState(false);
    const toggleMobileMenu = () =>{
        setSideShow(sideShow ? false : true);
     }

     const closeSidebar = () =>{
        setSideShow(false)
     }

    return ( 
        <>
          <div className='flex bg-[#E5E5E5] min-h-screen'>
                 <Sidebar sideShow={sideShow} handleClose={closeSidebar} />
                <div className='w-full px-4 md:px-6 py-6 overflow-y-auto h-full'>
                    <div className='flex justify-between items-center'>
                         <div className='flex gap-3 items-center'>
                            <div onClick={toggleMobileMenu} className='cursor-pointer block md:hidden'>
                                <img src={menu_icon} alt="menu" />
                            </div>
                            <div>
                                <h6 className='text-[#010918] font-Graphix500 text-lg'>{title}</h6>
                            </div>
                         </div>
                    </div>
                    {children}
                </div>
           </div>
        </>
     );
}
 
export default SideWrapper;