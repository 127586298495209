import React,{useRef, useState, useEffect} from 'react'
import logo_white_full from '../../assets/icons/logo_white_full.svg'
import logo_white from '../../assets/icons/logo_white.svg'
import dashboard from '../../assets/icons/dashboard.svg'
import dashboard_active from '../../assets/icons/dashboard_active.svg'
import topup from '../../assets/icons/topup.svg'
import topup_active from '../../assets/icons/topup_active.svg'
import paybills from '../../assets/icons/paybills.svg'
import paybills_active from '../../assets/icons/paybills_active.svg'
import transactions from '../../assets/icons/transactions.svg'
import transactions_active from '../../assets/icons/transactions_active.svg'
import bell from '../../assets/icons/bell.svg'
import bell_active from '../../assets/icons/bell_active.svg'
import settings from '../../assets/icons/settings.svg'
import settings_active from '../../assets/icons/settings_active.svg'
import schedule from '../../assets/icons/schedule.svg'
import schedule_active from '../../assets/icons/schedule_active.svg'
import more_icon from '../../assets/icons/more_icon.svg'
import user1 from '../../assets/images/user1.svg'
import chevron_left from '../../assets/icons/chevron_left.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import './sidebar.css'
import { Logout } from '../../redux/Auth/auth'

const Sidebar = ({sideShow, handleClose}) => {
  const { userInfo} = useSelector((state) => state.auth);
  const dispatch = useDispatch()
    const ref = useRef()
    const location = useLocation()
    const navigate = useNavigate()
    const [openSide, setOpenSide] = useState(false)
    const [isOpen, setIsOpen] = useState(false)

    const [isMoreOpen, setIsMoreOpen] = useState(false)
 

    const handleLogout = () => {
      dispatch(Logout())
      navigate('/')
  }

    const handleCloseSidebar = () =>{
        setOpenSide(!openSide)
    }

    const toggleDropdown = () =>{
      setIsOpen(!isOpen)
    }

    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          handleClose()
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref,handleClose])



  
    return ( 
        <>
          <div className={
            openSide ?
            'hidden lg:flex flex-col items-center w-28 min-h-screen  text-gray-400 bg-[#0A49BA] relative transition-all duration-[0.3s]'
            :
            'hidden lg:flex flex-col items-center w-1/4  min-h-screen text-gray-400 bg-[#0A49BA] relative transition-all duration-[0.3s]'
            }
            >
            <div class="flex items-center w-full px-8 mt-10">
                {
                openSide ?
                <img src={logo_white} alt="logo" className='w-[27px]' />
                    :
                <img src={logo_white_full} alt="logo"  className="w-[130px]" />
                }
            </div>
            
            {/* toggle */}
                  <div onClick={handleCloseSidebar} className='bg-[#F0F0F0] z-10 absolute -right-4 top-16 cursor-pointer w-[30px] h-[30px] rounded-full flex justify-center items-center border-[0.54px] border-[#D8D8D8]'>
                    <img src={chevron_left} alt="chevron left icon" />
                  </div>

                <div class="w-full px-4">
                    <div class="flex flex-col items-center w-full mt-20">
                        {
                            openSide 
                            ?
                            <Link to="/dashboard" className={
                                location.pathname.includes('/dashboard') 
                                ?
                                "flex items-center justify-center w-14 h-12 rounded-[5px] bg-[#CFDFFC] text-[#0041B1] font-Lota600"
                                :
                                 "flex items-center justify-center w-14 h-12 rounded-[5px] text-[#fff] font-Lota600"
                             }
                            >
                               <img src={
                                    location.pathname.includes('/dashboard') ?
                                        dashboard_active
                                        : 
                                        dashboard
                                      } 
                                  alt="dashboard icon" className='w-[16px] h-[16px]' />
                             </Link>
                            :
                            <Link to="/dashboard"
                             className=
                             {
                                location.pathname.includes('/dashboard') ?
                                "flex items-center w-full py-4 px-6 rounded-[5px] bg-[#CFDFFC] text-[#0041B1] font-medium "
                                :
                                "flex items-center w-full py-4 px-6 rounded-[5px] text-[#fff] font-medium "
                             }
                             >
                                  <img src={
                                    location.pathname.includes('/dashboard') ?
                                        dashboard_active
                                        : 
                                        dashboard
                                     } 
                                     alt="dashboard icon" className='w-[16px] h-[16px]' />
                            <span className="ml-3 text-sm ">Dashboard</span>
                             </Link>    
                        }

                        {
                            openSide 
                            ?
                           <div className=
                                {
                                    location.pathname.includes('/top-up') 
                                    ?
                                    "flex items-center justify-center w-14 h-12 mt-3 bg-[#CFDFFC] rounded-[5px] font-Lota400"
                                    :
                                    "flex items-center justify-center w-14 h-12 mt-3  rounded-[5px] font-Lota400"
                                }
                                >
                                <img src={
                                  location.pathname.includes('/top-up')
                                  ?
                                  topup_active
                                  :
                                  topup
                                } alt="topup icon" className='w-[16px] h-[16px]' />
                             </div>
                             :
                            <div className={
                              isOpen 
                              ?
                              "w-full bg-[#CFDFFC] rounded-[5px] mt-3 cursor-pointer"
                              :
                              "w-full rounded-[5px] mt-3 cursor-pointer"
                            }
                            >
                              <div className=
                                {
                                  isOpen
                                      ?
                                      "flex items-center w-full py-4 px-6 rounded-[5px] bg-[#CFDFFC] font-Lota400 text-[#0041B1]"
                                      :
                                      "flex items-center w-full py-4 px-6  rounded-[5px]  font-Lota400 text-white"
                                }onClick={toggleDropdown}
                                  >
                                  <img src={
                                    isOpen
                                    ?
                                    topup_active
                                    :
                                    topup
                                  } alt="topup icon" className='w-[16px] h-[16px] flex-shrink-0' />
                                  <span className="ml-3 text-sm flex-1">Top-Up</span>
                                    <svg
                                    className=
                                    {
                                      isOpen ?
                                      "w-4 h-4 transition-transform transform rotate-180"
                                      :
                                      "w-4 h-4 transition-transform transform"
                                    }
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                                  </svg>
                              </div>
                              <ul id="dropdown" className={
                                isOpen ? 'visible': 'hidden'
                              }>
                                    <li>
                                        <Link to="/top-up/airtime" className={
                                          location.pathname.includes('/top-up/airtime')
                                          ?
                                          "flex items-center w-full py-3 px-6 text-sm rounded-[5px] font-Lota600 text-[#0041B1] transition duration-75 hover:bg-gray-100"
                                          :
                                          "flex items-center w-full py-3 px-6 text-sm rounded-[5px] font-Lota600 text-[#010918] transition duration-75 hover:bg-gray-100"
                                        }
                                        >
                                          <span className="ml-7 text-sm flex-1">Airtime</span>
                                          </Link>
                                    </li>
                                    <li>
                                      <Link to="/top-up/data" className={
                                         location.pathname.includes('/top-up/data')
                                         ?
                                         "flex items-center w-full py-3 px-6 text-sm rounded-[5px] font-Lota600 text-[#0041B1] transition duration-75 hover:bg-gray-100"
                                         :
                                         "flex items-center w-full py-3 px-6 text-sm rounded-[5px] font-Lota600 text-[#010918] transition duration-75 hover:bg-gray-100"
                                       }>
                                        <span className="ml-7 text-sm flex-1">Data</span>
                                      </Link>
                                    </li>
                              </ul>
                            </div>

                        }

                        {
                            openSide 
                            ?
                            <Link to="/paybills" className=
                                {
                                    location.pathname.includes('/paybills') 
                                    ?
                                    "flex items-center justify-center w-14 h-12 rounded-[5px] mt-3 bg-[#CFDFFC] text-[#0041B1] font-Lota400 "
                                    :
                                    "flex items-center justify-center w-14 h-12  rounded-[5px] mt-3 text-[#fff] font-Lota400 "
                                }
                                >
                            <img src={
                                location.pathname.includes('/paybills')
                                ?
                                paybills_active
                                :
                                paybills
                                } alt="paybills icon" className='w-[16px] h-[16px]' />
                          </Link>
                          :
                          <Link to="/paybills" className=
                          {
                            location.pathname.includes('/paybills') 
                            ?
                            "flex items-center w-full py-4 px-6 mt-4 rounded-[5px] bg-[#CFDFFC]  font-Lota400 text-[#0041B1]"
                            :
                            "flex items-center w-full py-4 px-6 mt-4 rounded-[5px] font-Lota400 text-white"
                          }
                          >
                          <img src={
                                location.pathname.includes('/paybills')
                                ?
                                paybills_active
                                :
                                paybills
                                } 
                                 alt="paybills icon" className='w-[16px] h-[16px]' />
                          <span class="ml-4 text-sm ">Paybills</span>
                      </Link>
                        }

                        {
                            openSide 
                            ?
                            <Link to="/schedules" className=
                                {
                                    location.pathname.includes('/schedule') 
                                    ?
                                    "flex items-center justify-center w-14 h-12 rounded-[5px] mt-3 bg-[#CFDFFC] text-[#0041B1] font-Lota400 "
                                    :
                                    "flex items-center justify-center w-14 h-12 rounded-[5px] mt-3 text-[#fff] font-Lota400"
                                }
                                >
                            <img src={
                                location.pathname.includes('/schedule')
                                ?
                                schedule_active
                                :
                                schedule
                                } alt="schedule icon" className='w-[16px] h-[16px]' />
                          </Link>
                          :
                          <Link to="/schedules" className=
                          {
                            location.pathname.includes('/schedule') 
                            ?
                            "flex items-center w-full py-4 px-6 mt-3 rounded-[5px] bg-[#CFDFFC]  font-Lota400 text-[#0041B1]"
                            :
                            "flex items-center w-full py-4 px-6 mt-3 rounded-[5px] font-Lota400 text-white"
                          }
                          >
                          <img src={
                                location.pathname.includes('/schedule')
                                ?
                                schedule_active
                                :
                                schedule
                                } 
                                 alt="schedule icon" className='w-[16px] h-[16px]' />
                          <span class="ml-4 text-sm ">Schedule</span>
                      </Link>
                        }

                       
                        {
                        openSide
                            ?
                            <Link to="/transactions" className={
                                    location.pathname.includes('/transactions')
                                    ?
                                    "flex items-center justify-center w-14 h-12 rounded-[5px] mt-3 bg-[#CFDFFC] text-[#0041B1] font-Lota400"
                                    :
                                    "flex items-center justify-center w-14 h-12 rounded-[5px] mt-3 text-[#fff] font-Lota400"
                            }
                            >
                            <img src={
                                location.pathname.includes('/transactions')
                                ?
                                transactions_active
                                :
                                transactions
                                } 
                                alt="transactions icon" className='w-[16px] h-[16px]' />
                          </Link>
                          :
                          <Link to="/transactions" className={
                            location.pathname.includes('/transactions')
                            ?
                            "flex items-center w-full py-4 px-6 mt-3 rounded-[5px] bg-[#CFDFFC]  font-Lota400 text-[#0041B1]"
                            :
                            "flex items-center w-full py-4 px-6 mt-3 rounded-[5px] font-Lota400 text-white"
                          }
                          >
                          <img src={
                            location.pathname.includes('/transactions')
                            ?
                            transactions_active
                            :
                            transactions
                          } alt="transactions icon" className='w-[16px] h-[16px]' />
                          <span class="ml-3 text-sm ">Transactions</span>
                      </Link>

                        }
                       
                        {
                            openSide
                            ?
                            <Link to="/notification" className=
                            {
                            location.pathname.includes('/notification') 
                                ?
                            "flex items-center justify-center w-14 h-12 rounded-[5px] mt-3 bg-[#CFDFFC] text-[#0041B1] font-Lota400"
                            :
                            "flex items-center justify-center w-14 h-12 rounded-[5px] mt-3 text-[#fff] font-Lota400"
                            }
                            >
                            <img src={
                                  location.pathname.includes('/notification') 
                                  ?
                                  bell_active
                                  :
                                 bell
                                } alt="bell icon" className='w-[16px] h-[16px]' />
                          </Link>
                          :
                          <Link to="/notification" className={
                            location.pathname.includes('/notification') 
                            ?
                            "flex items-center w-full py-4 px-6 mt-3 rounded-[5px] bg-[#CFDFFC]  font-Lota400 text-[#0041B1]"
                            :
                            "flex items-center w-full py-4 px-6 mt-3 rounded-[5px] font-Lota400 text-white"
                          }
                          >
                          <img src={
                              location.pathname.includes('/notification') 
                              ?
                              bell_active
                              :
                            bell
                            } alt="bell icon" className='w-[16px] h-[16px]' />
                          <span class="ml-3 text-sm ">Notification</span>
                        </Link>
                        }
                       
                        {
                        openSide
                            ?
                            <Link to="/settings" className=
                            {
                                location.pathname.includes('/settings')
                                ?
                                "flex items-center justify-center w-14 h-12  rounded-[5px] bg-[#CFDFFC] mt-3 text-[#0041B1] font-Lota400 "
                                :
                                "flex items-center justify-center w-14 h-12  rounded-[5px] hover:bg-[#CFDFFC] mt-3 text-[#fff] font-Lota400 "
                            }
                            >
                            <img src={
                              location.pathname.includes('/settings')
                                ?
                                settings_active
                                :
                                settings} alt="settings icon" className='w-[16px] h-[16px]' />
                          </Link>
                          :
                          <Link to="/settings" className={
                            location.pathname.includes('/settings')
                            ?
                            "flex items-center w-full py-4 px-6 mt-3 rounded-[5px] bg-[#CFDFFC] font-Lota400 text-[#0041B1]"
                            :
                            "flex items-center w-full py-4 px-6 mt-3 rounded-[5px] text-white font-Lota400 "
                          }
                         >
                          <img src={
                              location.pathname.includes('/settings')
                                ?
                                settings_active
                                :
                                settings}
                                 alt="settings icon" className='w-[16px] h-[16px]' />
                          <span class="ml-3 text-sm ">Settings</span>
                      </Link>
                        }
                     
                    </div>
                   
                </div>
        
                <div class="bg-[#F6F6F8]  flex justify-between items-center mt-auto w-full px-4 h-16">
                    {
                        openSide ?
                            <div className='mx-auto'>
                                <img className="rounded-full w-[40px] h-[40px]" src={user1} alt="avatar" />
                            </div>
                        :
                        <>
                          <div class="flex justify-center items-center space-x-2">
                            <div>
                                <img className="rounded-full w-[40px] h-[40px]" src={user1} alt="avatar" />
                            </div>
                            <div className="flex justify-start flex-col items-start">
                            <p className="cursor-pointer text-sm font-Lota600 text-[#010918]">{userInfo ? userInfo.firstName : 'Test'} {userInfo ? userInfo.lastName: 'User'}</p>
                            <p className="cursor-pointer text-xs font-Lota400 text-[#010918]">{userInfo ? userInfo.email: 'N/A'}</p>
                            </div>
                        </div>
                        <div className='relative'>
                            <img src={more_icon} alt="more icon" onClick={() => setIsMoreOpen(!isMoreOpen)} className='cursor-pointer' />
                            {isMoreOpen && 
                            <div className=''>
                              <button
                                 onClick={handleLogout}
                               className='absolute right-0 bottom-5 hover:bg-[#0A49BA] hover:text-white transition-all bg-[#fff] px-7 py-3 rounded-[5px] text-[#0A49BA] border border-[#0A49BA] text-sm font-Lota400'>Logout</button>
                            </div>
                               }
                        </div>
                   
                        </>
                    }
                  
                 
                </div>
            </div>

            {/* mobile menu */}
            <div ref={ref} className={
            sideShow ?
            'md:hidden fixed z-10 flex flex-col items-center w-64 min-h-screen userside show text-gray-400 bg-[#0A49BA] transition-all duration-[0.3s]'
            :
            'md:hidden flex-col items-center w-64 min-h-screen userside text-gray-400 bg-[#0A49BA] fixed z-10 transition-all duration-[0.3s]'
            }
            >
              <div class="flex items-center w-full px-8 mt-10">
                  <img src={logo_white_full} alt="logo"  className="w-[130px]" />
              </div>

               <div class="w-full px-4">
                    <div class="flex flex-col items-center w-full mt-20">
                       
                            <Link to="/dashboard"
                             className=
                             {
                                location.pathname.includes('/dashboard') ?
                                "flex items-center w-full py-4 px-6 rounded-[5px] bg-[#CFDFFC] text-[#0041B1] font-medium "
                                :
                                "flex items-center w-full py-4 px-6 rounded-[5px] text-[#fff] font-medium "
                             }
                             >
                                  <img src={
                                    location.pathname.includes('/dashboard') ?
                                        dashboard_active
                                        : 
                                        dashboard
                                     } 
                                     alt="dashboard icon" className='w-[16px] h-[16px]' />
                            <span className="ml-3 text-sm ">Dashboard</span>
                             </Link>    
                        

                   
                            <div className={
                              isOpen 
                              ?
                              "w-full bg-[#CFDFFC] rounded-[5px] mt-3 cursor-pointer"
                              :
                              "w-full rounded-[5px] mt-3 cursor-pointer"
                            }
                            >
                              <div className=
                                {
                                  isOpen
                                      ?
                                      "flex items-center w-full py-4 px-6 rounded-[5px] bg-[#CFDFFC] font-Lota400 text-[#0041B1]"
                                      :
                                      "flex items-center w-full py-4 px-6  rounded-[5px]  font-Lota400 text-white"
                                }onClick={toggleDropdown}
                                  >
                                  <img src={
                                    isOpen
                                    ?
                                    topup_active
                                    :
                                    topup
                                  } alt="topup icon" className='w-[16px] h-[16px] flex-shrink-0' />
                                  <span className="ml-3 text-sm flex-1">Top-Up</span>
                                    <svg
                                    className=
                                    {
                                      isOpen ?
                                      "w-4 h-4 transition-transform transform rotate-180"
                                      :
                                      "w-4 h-4 transition-transform transform"
                                    }
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                                  </svg>
                              </div>
                              <ul id="dropdown" className={
                                isOpen ? 'visible': 'hidden'
                              }>
                                    <li>
                                        <Link to="/top-up/airtime" className={
                                          location.pathname.includes('/top-up/airtime')
                                          ?
                                          "flex items-center w-full py-3 px-6 text-sm rounded-[5px] font-Lota600 text-[#0041B1] transition duration-75 hover:bg-gray-100"
                                          :
                                          "flex items-center w-full py-3 px-6 text-sm rounded-[5px] font-Lota600 text-[#010918] transition duration-75 hover:bg-gray-100"
                                        }
                                        >
                                          <span className="ml-7 text-sm flex-1">Airtime</span>
                                          </Link>
                                    </li>
                                    <li>
                                      <Link to="/top-up/data" className={
                                         location.pathname.includes('/top-up/data')
                                         ?
                                         "flex items-center w-full py-3 px-6 text-sm rounded-[5px] font-Lota600 text-[#0041B1] transition duration-75 hover:bg-gray-100"
                                         :
                                         "flex items-center w-full py-3 px-6 text-sm rounded-[5px] font-Lota600 text-[#010918] transition duration-75 hover:bg-gray-100"
                                       }>
                                        <span className="ml-7 text-sm flex-1">Data</span>
                                      </Link>
                                    </li>
                              </ul>
                            </div>

                        

                       
                          <Link to="/paybills" className=
                          {
                            location.pathname.includes('/paybills') 
                            ?
                            "flex items-center w-full py-4 px-6 mt-4 rounded-[5px] bg-[#CFDFFC]  font-Lota400 text-[#0041B1]"
                            :
                            "flex items-center w-full py-4 px-6 mt-4 rounded-[5px] font-Lota400 text-white"
                          }
                          >
                          <img src={
                                location.pathname.includes('/paybills')
                                ?
                                paybills_active
                                :
                                paybills
                                } 
                                 alt="paybills icon" className='w-[16px] h-[16px]' />
                          <span class="ml-4 text-sm ">Paybills</span>
                      </Link>
                        

                       
                          <Link to="/schedules" className=
                          {
                            location.pathname.includes('/schedule') 
                            ?
                            "flex items-center w-full py-4 px-6 mt-3 rounded-[5px] bg-[#CFDFFC]  font-Lota400 text-[#0041B1]"
                            :
                            "flex items-center w-full py-4 px-6 mt-3 rounded-[5px] font-Lota400 text-white"
                          }
                          >
                          <img src={
                                location.pathname.includes('/schedule')
                                ?
                                schedule_active
                                :
                                schedule
                                } 
                                 alt="schedule icon" className='w-[16px] h-[16px]' />
                          <span class="ml-4 text-sm ">Schedule</span>
                      </Link>
                        

                       
                     
                          <Link to="/transactions" className={
                            location.pathname.includes('/transactions')
                            ?
                            "flex items-center w-full py-4 px-6 mt-3 rounded-[5px] bg-[#CFDFFC]  font-Lota400 text-[#0041B1]"
                            :
                            "flex items-center w-full py-4 px-6 mt-3 rounded-[5px] font-Lota400 text-white"
                          }
                          >
                          <img src={
                            location.pathname.includes('/transactions')
                            ?
                            transactions_active
                            :
                            transactions
                          } alt="transactions icon" className='w-[16px] h-[16px]' />
                          <span class="ml-3 text-sm ">Transactions</span>
                      </Link>

                        
                       
                       
                          <Link to="/notification" className={
                            location.pathname.includes('/notification') 
                            ?
                            "flex items-center w-full py-4 px-6 mt-3 rounded-[5px] bg-[#CFDFFC]  font-Lota400 text-[#0041B1]"
                            :
                            "flex items-center w-full py-4 px-6 mt-3 rounded-[5px] font-Lota400 text-white"
                          }
                          >
                          <img src={
                              location.pathname.includes('/notification') 
                              ?
                              bell_active
                              :
                            bell
                            } alt="bell icon" className='w-[16px] h-[16px]' />
                          <span class="ml-3 text-sm ">Notification</span>
                        </Link>
                        
                       
                  
                          <Link to="/settings" className={
                            location.pathname.includes('/settings')
                            ?
                            "flex items-center w-full py-4 px-6 mt-3 rounded-[5px] bg-[#CFDFFC] font-Lota400 text-[#0041B1]"
                            :
                            "flex items-center w-full py-4 px-6 mt-3 rounded-[5px] text-white font-Lota400 "
                          }
                         >
                          <img src={
                              location.pathname.includes('/settings')
                                ?
                                settings_active
                                :
                                settings}
                                 alt="settings icon" className='w-[16px] h-[16px]' />
                          <span class="ml-3 text-sm ">Settings</span>
                      </Link>
                         
                    </div>
                </div>

                <div class="bg-white flex justify-between items-center mt-auto w-full px-4 h-16">
                        <>
                          <div class="flex justify-center items-center space-x-2">
                            <div>
                                <img className="rounded-full w-[40px] h-[40px]" src={user1} alt="avatarcc" />
                            </div>
                            <div className="flex justify-start flex-col items-start">
                            <p className="cursor-pointer text-sm font-Lota600 text-[#010918]">{userInfo ? userInfo.firstName : 'Test'} {userInfo ? userInfo.lastName: 'User'}</p>
                            <p className="cursor-pointer text-xs font-Lota400 text-[#010918]">{userInfo ? userInfo.email: 'N/A'}</p>
                            </div>
                        </div>   
                         <img src={more_icon} alt="more icon" className='cursor-pointer' />
                       </>
                </div>

            </div>
        </>
     );
}
 
export default Sidebar;