import * as Yup from 'yup'

export const loginValidator = Yup.object({
  email: Yup.string()
    .email('Enter a valid email')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
})

export const forgotPasswordValidator = Yup.object({
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
});

export const resetPasswordValidator = Yup.object({
  password: Yup.string()
    .min(6, 'Password cannot be less than 6 characters')
    .required('Password is required'),
  confirm_password: Yup.string()
    .required('Passwords must match')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
})

export const registerValidator = Yup.object({
  email: Yup.string()
    .email('Enter a valid email')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
})

export const PinValidator = Yup.object({
  pin: Yup.string()
    .min(4, "Pin cannot be less than 4 characters")
    .max(4, "Pin cannot be more than 4 characters")
    .required("Pin is required")
    .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid pin"),
  confirm_pin: Yup.string()
    .required("Pin must match")
    .oneOf([Yup.ref("pin"), null], "Pin must match"),
});

export const TransactionPinValidator = Yup.object({
  pin: Yup.string()
    .min(4, "Pin cannot be less than 4 characters")
    .max(4, "Pin cannot be more than 4 characters")
    .required("Pin is required")
    .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid pin"),
});

export const ChangePasswordValidator = Yup.object({
  oldpassword: Yup.string().required("Password is required"),
  password: Yup.string().required("Enter a new password"),
  confirm_password: Yup.string()
    .required("Passwords must match")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export const ChangePinValidator = Yup.object({
  oldpin:  Yup.string()
  .min(4, "Pin cannot be less than 4 characters")
  .max(4, "Pin cannot be more than 4 characters")
  .required("Pin is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid pin"),
  pin: Yup.string()
  .min(4, "Pin cannot be less than 4 characters")
  .max(4, "Pin cannot be more than 4 characters")
  .required("Pin is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid pin"),
 confirm_pin: Yup.string()
  .required("Pin must match")
  .oneOf([Yup.ref("pin"), null], "Pin must match"),
});

export const bvnValidator = Yup.object({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  bvn: Yup.string().required('BVN is required'),
  dob: Yup.string().required('Date of Birth is required'),
})

export const singleAirtimeValidator = Yup.object({
  phoneNumber: Yup.string().required('Phone Number is required'),
  amount: Yup.string()
  .required("Enter an amount")
  .matches(/^[0-9]*\.?[0-9]*$/, "Enter a valid amount"),
  pin: Yup.string()
  .min(4, "Pin cannot be less than 4 characters")
  .max(4, "Pin cannot be more than 4 characters")
  .required("Pin is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid pin")
})

export const singleDataValidator = Yup.object({
  phoneNumber:  Yup.string()
  .min(11, "Phone number cannot be less than 11 digits")
  .max(11, "Exceeded characters for phone number")
  .required("Phone Number is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid phone number"),
  bundles: Yup.string().required('Bundle is required'),
  pin: Yup.string()
  .min(4, "Pin cannot be less than 4 characters")
  .max(4, "Pin cannot be more than 4 characters")
  .required("Pin is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid pin")
})


export const singleElectricityValidator = Yup.object({
  meterNumber: Yup.string().required('Meter Number is required'),
  phoneNumber: Yup.string()
  .min(11, "Phone number cannot be less than 11 digits")
  .max(11, "Exceeded characters for phone number")
  .required("Phone Number is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid phone number"),
  email: Yup.string()
  .email("Enter a valid email")
  .required("Email is required"),
  amount: Yup.string()
  .required("Enter an amount")
  .matches(/^[0-9]*\.?[0-9]*$/, "Enter a valid amount"),
  pin: Yup.string()
  .min(4, "Pin cannot be less than 4 characters")
  .max(4, "Pin cannot be more than 4 characters")
  .required("Pin is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid pin")
})

export const singleCablesValidator = Yup.object({
  smartNumber: Yup.string().required('Smart Number is required'),
  plan: Yup.string().required('Subscription Plan is required'),
  phoneNumber: Yup.string()
  .min(11, "Phone number cannot be less than 11 digits")
  .max(11, "Exceeded characters for phone number")
  .required("Phone Number is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid phone number"),
  email: Yup.string()
  .email("Enter a valid email")
  .required("Email is required"),
  pin: Yup.string()
  .min(4, "Pin cannot be less than 4 characters")
  .max(4, "Pin cannot be more than 4 characters")
  .required("Pin is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid pin")
})






