import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import {apiUrl} from '../config'
import cogoToast from "cogo-toast"


const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};


export const userLogin = createAsyncThunk(
    'login',
    async ({ email, password }, { rejectWithValue }) => {
      try {
        const res = await axios.post(
          `${apiUrl}auth/member/login`,
          { 
            email: email, 
            password,
          }
        )
        if(res.status === 200){
          let isVerified = res.data.data.profile.isVerified
          // If email verification is false, user needs to verify their email address...
            if(!isVerified){
              return res.data.data
            }
            else{
              cogoToast.success("Login Successful!",{
                position: 'bottom-right'
              })
             return res.data.data
            }
        }
      } catch (error) {
        if(error.response.status === 401){
          cogoToast.error('Invalid Credentials!')
          return rejectWithValue(error.response)
        }
        else{
          cogoToast.error('Invalid Credentials!')
          return rejectWithValue(error.response)
        }
      }
    }
)

export const userRegister = createAsyncThunk(
  'register',
  async ({ email, password, phoneNumber, firstName, lastName }, { rejectWithValue, dispatch }) => {
    dispatch({type: 'auth/UserEmail', email})
    try {
      const res = await axios.post(
        `${apiUrl}auth/member/signup`,
        { 
          email, 
          firstName,
          lastName,
          password,
          phoneNumber,
        }
      )
      if(res.status === 201){
          cogoToast.success("Registration Successful!, Kindly login to proceed");
          return res.data.data
      }
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data) {
          cogoToast.error('Oops! Looks like a user already exist with this credentials.')
        return rejectWithValue(error.response.data.error)
      } else {
          cogoToast.error('Oops! Looks like a user already exist with this credentials.')
        return rejectWithValue(error.response.data.error)
      }
    }
  }
)

export const verifyEmail = createAsyncThunk(
  'verifyEmail',
  async ({code}, {rejectWithValue, dispatch}) => {

    try {
      const res = await axios.post(
        `${apiUrl}auth/verify/user`,
        {
          verificationCode: code
        }
      )
      if(res.status === 200){
        cogoToast.success("Email verified Successfully!");
          return res.data
      }
      
    } catch (error) {
      // return custom error message from API if any
      if(error.response.status === 400 || error.response.status  === 403){
        cogoToast.error('Oops, looks like the code is either expired or is invalid!')
        return rejectWithValue(error.response)
      }
      else if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const resendVerifyEmail = createAsyncThunk(
  'resendVerifyEmail',
  async ({email}, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}auth/resendverificationlink`,
        {
          email: email
        },
      )
      if(res.status === 200 || res.status === 202){
        cogoToast.success(
          "A verification code has been sent to you, check your inbox!",
          {
            position: "top-center",
          }
        );
          return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if(error.response.status === 400 || error.response.status === 422){
        return rejectWithValue(error.response)
      }
      else if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const userForgot = createAsyncThunk(
  'forgot',
  async ({ email }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}auth/forgot/password`,
        { 
          email: email, 
        }
      )
      if(res.status === 200){
        cogoToast.success(
          "Check your email for password reset instructions!",
          {
            position: "top-center",
          }
        );
          return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if(error.response.status === 400 || error.response.status === 422){
        cogoToast.error('Kindly check that the credentials entered is valid!')
        return rejectWithValue(error.response)
      }
      else if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const userResetPassword = createAsyncThunk(
  'userResetPassword',
  async ({ code, password }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}auth/reset/password`,
        { 
          code,
          newPassword : password
        }
      )
      if(res.status === 200){
        cogoToast.success(
          "Your password has been changed successfully, Kindly login again!",
          {
            position: "top-center",
          }
        );
          return res.data
      }
    } catch (error) {
       // return custom error message from API if any
       if(error.response.status === 400){
        cogoToast.error('Oops, looks like the link has either expired or is invalid, please request again.')
        return rejectWithValue(error.response)
      }
      else if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const userCreatePin = createAsyncThunk(
  'userCreatePin',
  async ({ pin }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(
        `${apiUrl}auth/create-pin`,
        { 
          pin: parseInt(pin), 
        },
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${getToken()}`
          }, 
        }
      )
      if(res.status === 201){
        cogoToast.success(
          "Pin created successfully!",
          {
            position: "top-center",
          }
        );
          return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if(error.response.status === 400){
        cogoToast.error("Oops looks like you've created a pin earlier!")
        return rejectWithValue(error.response)
      }
      else if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const updateUserPassword = createAsyncThunk(
  'updateUserPassword',
  async ({ oldpassword, password }, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await axios.put(
        `${apiUrl}auth/change-password`,
        { 
         oldPassword: oldpassword,
         newPassword: password,
        },
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
        localStorage.setItem("token", "");
         dispatch({type: 'auth/Logout'})
        cogoToast.success("Password successfully changed, Login to continue!", {
          position: "top-right",
          });
          return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if(error.response.status === 400){
        cogoToast.error('Check that your old password is correct!')
      }
      else if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const updateUserPin = createAsyncThunk(
  'updateUserPin',
  async ({ oldpin, pin }, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await axios.put(
        `${apiUrl}auth/change-pin`,
        { 
         oldPin: parseInt(oldpin),
         newPin: parseInt(pin)
        },
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
        localStorage.setItem("token", "");
         dispatch({type: 'auth/Logout'})
        cogoToast.success("Pin successfully changed!", {
          position: "top-right",
          });
          return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if(error.response.status === 400){
        cogoToast.error('Check that your old pin is correct!')
      }
      else if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const userVerifyID = createAsyncThunk(
  'userVerifyID',
  async ({ bvn, firstName, lastName, dob }, { rejectWithValue, dispatch, getState }) => {
    let email = getState().auth.userInfo.email
    let phoneNumber = getState().auth.userInfo.phoneNumber
    try {
      const res = await axios.post(
        `${apiUrl}auth/id-verify`,
        { 
          email, 
          phone: phoneNumber,
          idType: 'BVN',
          idNumber: bvn,
          firstName,
          lastName,
          dob
        },
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${getToken()}`
          }, 
        },
      )
      if(res.status === 200){
        cogoToast.success(
          "BVN verification successful, Your account number would be generated shortly!",
          {
            position: "top-center",
          }
        );
          return res.data
      }
    } catch (error) {
      // return custom error message from API if any
      if(error.response.status === 400){
        cogoToast.error(`${error.response.data.message}`)
        return rejectWithValue(error.response)
      }
      else if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)

export const userWalletDetails = createAsyncThunk(
  'userWalletDetails',
  async (arg, {rejectWithValue, dispatch, getState}) => {
    let id = getState().auth.userInfo.memberId
    try {
      const res = await axios.get(
        `${apiUrl}wallet/${id}`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data.data
      }
      
    } catch (error) {
      // return custom error message from API if any
      if(error.response.status === 400 || error.response.status  === 403){
        cogoToast.error('Oops, looks like there was an error getting your wallet details!')
        return rejectWithValue(error.response)
      }
      else if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)