import React,{ useEffect, useState} from 'react'
import coins_icon from '../../assets/icons/coins.svg'
// import add_icon from '../../assets/icons/add_icon.svg'
import chevron_blue_right from '../../assets/icons/chevron_blue_right.svg'
import lock_icon from '../../assets/icons/lock.svg'
import { Link } from 'react-router-dom';
import Modal from '../../components/Modals/modal'
import eye from '../../assets/icons/eye.svg'
import eye_off from '../../assets/icons/eye_off.svg'
import graph_icon from '../../assets/icons/Graph.svg'
import emoji1 from '../../assets/icons/emoji1.svg'
import { Form, Formik } from 'formik'
import { PinValidator, bvnValidator } from '../../validationSchema/validator'
import { useSelector } from 'react-redux'
// import DoughnutChart from '../../components/Charts/Doughnut'
import { useDispatch } from 'react-redux'
import { userVerifyID, userWalletDetails } from '../../redux/Auth/authActions'

const DashboardPage = () => {
    const { userInfo, isIdVerified, loading, virtualAccountNumber, balance} = useSelector((state) => state.auth);
    const [showBVNModal, setShowBVNModal] = useState(false)
    const [showPinModal, setShowPinModal] = useState(false)

    const dispatch = useDispatch()

    const BVNModal = () =>{
        setShowBVNModal(!showBVNModal)
    }

    const PinModal = () => {
        setShowPinModal(!showPinModal)
    }

   
    const [passwordShown, setPasswordShown] = useState(false)
    const [confirmpasswordShown, setconfirmPasswordShown] = useState(false);

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true)
    }

    const toggleConfirmPasswordVisiblity = () => {
        setconfirmPasswordShown(confirmpasswordShown ? false : true);
      };

    const handleVerifyBVN = (values) => {
        console.log(values)
        dispatch(userVerifyID(values))
    }
    
    const handleSubmit = (values) =>{
        console.log(values)
    }

    useEffect(()=>{
        if(!isIdVerified){
            setTimeout(()=>{
                setShowBVNModal(true)
            },1000)
        }
    },[isIdVerified])

    useEffect(()=>{
        dispatch(userWalletDetails())
    },[dispatch])


    return ( 
        <>
          <Modal show={showBVNModal} title="BVN Verification"  showModal={BVNModal} 
            >
            <div className='mt-2'>
                <div className='bg-[#F9F9F9] flex gap-4 rounded-lg py-4 px-6'>
                    <div>
                        <img src={lock_icon} alt="lock" className='w-[24px] h-[24px]' />
                    </div>
                    <div>
                        <p className='text-[#242426] text-sm text-center font-Graphix400'>
                        Are you having issues with your BVN verification? <br/> contact support at <span className='font-Graphix500'>support@batchbills.com</span>
                        </p>
                    </div>
                </div>
               
                <div className='mt-4 max-w-md mx-auto'>
                <Formik
                    onSubmit={(values, { setSubmitting }) =>
                    handleVerifyBVN(values, setSubmitting)
                    }
                    validationSchema={bvnValidator}
                    enableReinitialize={true}
                    initialValues={{
                    firstName: userInfo ? userInfo.firstName: '',
                    lastName: userInfo ? userInfo.lastName: '',
                    bvn: '',
                    dob: '',
                    }}
                >
                    {({
                    handleChange,
                    isSubmitting,
                    handleSubmit,
                    handleBlur,
                    values,
                    touched,
                    errors,
                    }) => (
                    <Form className="mt-6" onSubmit={handleSubmit}>
                        <div>
                        <label htmlFor='firstName' className="block mb-2 text-[#333F51] text-sm font-Graphix500">
                            First Name
                        </label>
                        <input
                            type="text"
                            name="firstName"
                            placeholder="Enter your Firstname"
                            className={
                            touched.firstName && errors.firstName
                                ? 'appearance-none w-full placeholder:text-[#8896AB] font-Graphix400 placeholder:text-sm  text-[#8896AB] text-sm focus:border-error focus:outline-none rounded-lg border border-error bg-white py-4 px-4'
                                : 'appearance-none w-full placeholder:text-[#8896AB] font-Graphix400 placeholder:text-sm text-[#8896AB] text-sm focus:border-[#D5DAE1] shadow-input  focus:outline-none focus:shadow-input rounded-lg border border-[#D5DAE1] bg-white py-4 px-4'
                            }
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {touched.firstName && errors.firstName ? (
                            <small className="text-[#FF002A] font-[300] text-[10px] pt-[4px]">
                                {touched.firstName && errors.firstName}
                            </small>
                        ) : null}
                        </div>

                        <div className='mt-5'>
                        <label htmlFor='lastName' className="block mb-2 text-[#333F51] text-sm font-Graphix500">
                            Last Name
                        </label>
                        <input
                            type="text"
                            name="lastName"
                            placeholder="Enter your Lastname"
                            className={
                            touched.lastName && errors.lastName
                                ? 'appearance-none w-full placeholder:text-[#8896AB] font-Graphix400 placeholder:text-sm  text-[#8896AB] text-sm focus:border-error focus:outline-none rounded-lg border border-error bg-white py-4 px-4'
                                : 'appearance-none w-full placeholder:text-[#8896AB] font-Graphix400 placeholder:text-sm text-[#8896AB] text-sm focus:border-[#D5DAE1] shadow-input  focus:outline-none focus:shadow-input rounded-lg border border-[#D5DAE1] bg-white py-4 px-4'
                            }
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {touched.lastName && errors.lastName ? (
                            <small className="text-[#FF002A] font-[300] text-[10px] pt-[4px]">
                                {touched.lastName && errors.lastName}
                            </small>
                        ) : null}
                        </div>

                        <div className='mt-5'>
                        <label htmlFor='bvn' className="block mb-2 text-[#333F51] text-sm font-Graphix500">
                           BVN <small className='text-[#FF002A] text-xs'>*</small>
                        </label>
                        <input
                            type="text"
                            name="bvn"
                            placeholder="Enter your BVN"
                            className={
                            touched.bvn && errors.bvn
                                ? 'appearance-none w-full placeholder:text-[#8896AB] font-Graphix400 placeholder:text-sm  text-[#8896AB] text-sm focus:border-error focus:outline-none rounded-lg border border-error bg-white py-4 px-4'
                                : 'appearance-none w-full placeholder:text-[#8896AB] font-Graphix400 placeholder:text-sm text-[#8896AB] text-sm focus:border-[#D5DAE1] shadow-input  focus:outline-none focus:shadow-input rounded-lg border border-[#D5DAE1] bg-white py-4 px-4'
                            }
                            value={values.bvn}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {touched.bvn && errors.bvn ? (
                            <small className="text-[#FF002A] font-[300] text-[10px] pt-[4px]">
                                {touched.bvn && errors.bvn}
                            </small>
                        ) : null}
                        </div>

                        <div className='mt-5'>
                        <label htmlFor='dob' className="block mb-2 text-[#333F51] text-sm font-Graphix500">
                        Date Of Birth <small className='text-[#FF002A] text-xs'>*</small>
                        </label>
                        <input
                            type="date"
                            name="dob"
                            placeholder="YYYY/MM/DD"
                            className={
                            touched.dob && errors.dob
                                ? 'appearance-none w-full placeholder:text-[#8896AB] font-Graphix400 placeholder:text-sm  text-[#8896AB] text-sm focus:border-error focus:outline-none rounded-lg border border-error bg-white py-4 px-4'
                                : 'appearance-none w-full placeholder:text-[#8896AB] font-Graphix400 placeholder:text-sm text-[#8896AB] text-sm focus:border-[#D5DAE1] shadow-input  focus:outline-none focus:shadow-input rounded-lg border border-[#D5DAE1] bg-white py-4 px-4'
                            }
                            value={values.dob}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        {touched.dob && errors.dob ? (
                            <small className="text-[#FF002A] font-[300] text-[10px] pt-[4px]">
                                {touched.dob && errors.dob}
                            </small>
                        ) : null}
                        </div>
                           
                        <div className="mt-6">
                        <button
                            type="submit"
                            disabled={loading}
                            className="bg-[#0041B1] 
                            disabled:bg-[#0041B1] 
                            disabled:opacity-[0.7]
                            text-sm
                            font-Graphix500
                            rounded-lg w-full py-4 text-[#F0FDF4] hover:bg-[#0041B1] hover:opacity-[0.9] transition-all"
                        >
                            Verify
                        </button>
                        </div>
                    </Form>
                    )}
                </Formik>
                     <div className='mt-4 text-center'>
                        <button
                        onClick={BVNModal}
                        className='text-[#898CA6] font-Graphix500 text-sm'
                        >Cancel</button>
                     </div>
                </div>
            </div>
            </Modal>

            <Modal show={showPinModal} title="Transaction Pin" showModal={PinModal} >
                <div className='mt-2'>
                  <p className='text-[#898CA6] font-Graphix500 text-sm text-center'>Set Your 4 digit Transaction Pin.</p>
                </div>
                <div className='max-w-sm mx-auto'>
                     <Formik
                    onSubmit={(values, { setSubmitting }) =>
                    handleSubmit(values, setSubmitting)
                    }
                    validationSchema={PinValidator}
                    initialValues={{
                    pin: '',
                    confirm_pin: '',
                    }}
                >
                    {({
                    handleChange,
                    isSubmitting,
                    handleSubmit,
                    handleBlur,
                    values,
                    touched,
                    errors,
                    }) => (
                    <Form className="mt-6" onSubmit={handleSubmit}>
                        <div>
                        <label htmlFor='pin' className="block mb-2 text-[#333F51] text-sm font-medium">
                            Enter Pin
                        </label>
                        <div className='relative'>
                        <input
                            type={passwordShown ? 'text' : 'password'}
                            name="pin"
                            placeholder="Enter your pin"
                            className={
                            touched.pin && errors.pin
                                ? 'appearance-none w-full placeholder:text-[#8896AB] placeholder:text-sm  text-[#8896AB] text-sm focus:border-error focus:outline-none rounded-lg border border-error bg-white py-4 px-4'
                                : 'appearance-none w-full placeholder:text-[#8896AB] placeholder:text-sm text-[#8896AB] text-sm focus:border-[#D5DAE1] shadow-input  focus:outline-none focus:shadow-input rounded-lg border border-[#D8D8D8] bg-white py-4 px-4'
                            }
                            value={values.pin}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <img
                            onClick={togglePasswordVisiblity}
                            src={passwordShown ? eye_off : eye}
                            alt="eye"
                            className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                            />

                        </div>
                      
                        {touched.pin && errors.pin ? (
                            <small className="text-[#FF002A] font-[300] text-[10px] pt-[4px]">
                                {touched.pin && errors.pin}
                            </small>
                        ) : null}
                        </div>

                        <div className="mt-5">
                        <label htmlFor='confirm_pin' className="block mb-2 text-[#333F51] text-sm font-medium">
                            Confirm Pin
                        </label>
                        <div className="relative">
                            <input
                            type={confirmpasswordShown ? 'text' : 'password'}
                            name="confirm_pin"
                            placeholder="Confirm your pin"
                            className={
                                touched.confirm_pin && errors.confirm_pin
                                ? 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm  text-[#8896AB] text-sm focus:border-error focus:outline-none rounded-lg border border-error bg-white py-4 px-4'
                                : 'appearance-none w-full placeholder:text-[#96A397] placeholder:text-sm text-[#8896AB] text-sm focus:border-[#D5DAE1] focus:outline-none shadow-input focus:shadow-input rounded-lg border border-[#D8D8D8] bg-white py-4 px-4'
                            }
                            value={values.confirm_pin}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            />
                            <img
                            onClick={toggleConfirmPasswordVisiblity}
                            src={confirmpasswordShown ? eye_off : eye}
                            alt="eye"
                            className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
                            />
                        </div>
                        {touched.confirm_pin && errors.confirm_pin ? (
                            <small className="text-[#FF002A] font-[300] text-[10px] pt-[4px]">
                                {touched.confirm_pin && errors.confirm_pin}
                            </small>
                        ) : null}
                        </div>

                        <div className="mt-6">
                        <button
                            type="submit"
                            disabled={false}
                            className="bg-[#0041B1] 
                            disabled:bg-[#0041B1] 
                            disabled:opacity-[0.7]
                            text-sm
                            rounded-lg w-full py-4 text-[#F0FDF4] font-medium hover:bg-[#0041B1] hover:opacity-[0.9] transition-all"
                        >
                            Confirm
                        </button>
                        </div>

                        <div onClick={PinModal} className='text-center mt-5'>
                            <p className='text-[#8896AB] text-sm font-medium cursor-pointer'>Skip for now 
                             </p>
                        </div>

                    
                    </Form>
                    )}
                     </Formik>
                </div>
            </Modal>

            {/* main */}
            <div className='mt-5 md:mt-10 bg-[#FFFFFF] rounded-xl py-6 px-6 '>
                <div>
                    <h4 className='text-[#010918] text-lg font-Graphix500'>Welcome {userInfo ? userInfo.firstName: 'Anonymous'}!</h4>
                </div>

                <div className='mt-4 grid md:grid-cols-2 gap-4'>
                    <div className='border-[0.98px] border-[#D8D8D8] rounded-[10px] py-8 px-5 h-fit'>
                        <div>
                            <img src={graph_icon} alt="look out" />
                        </div>
                        <div className='flex justify-between mt-5 items-center'>
                            <div>
                                <h6 className='text-[#010918] text-sm font-Graphix400'>Bank Name</h6>
                                <h4 className='text-[#010918] font-medium  text-lg font-Graphix500'>{virtualAccountNumber ? 'LifeGate MFB' : 'Pending' }</h4>
                            </div>
                            <div>
                                <h6 className='text-[#010918] text-sm text-right font-Graphix400'>Account Number</h6>
                                <h4 className='text-[#010918] font-medium text-right text-lg font-Graphix500'>{virtualAccountNumber ? virtualAccountNumber: 'Pending' }</h4>
                            </div>
                        </div>

                        <div className='mt-12 flex justify-between items-center'>
                        <div>
                                <h6 className='text-[#010918] text-sm font-Graphix400'>Wallet Balance</h6>
                                <h4 className='text-[#010918] font-medium  text-lg font-Graphix500'>NGN {balance ? balance : 0}</h4>
                            </div>
                            <div>
                                <img src={coins_icon} alt="coins" />
                            </div>
                        </div>
                    </div>
                    <div className='border-[0.98px] border-[#D8D8D8] rounded-[10px] py-6 px-4'>
                        <div>
                            <h6 className='text-[#010918] text-sm font-Lota600'>Activity Summary</h6> 
                        </div>
                        {/* <div>
                          <DoughnutChart />
                        </div> */}
                    </div>
                </div>

                <div className='border-[0.98px] border-[#D8D8D8] rounded-[10px] py-6 px-4 mt-6 min-h-[200px]'>
                    <div>
                        <h6 className='text-[#010918] text-sm font-Lota600'>Inflow & Outflow</h6>
                    </div>
                  

                </div>

                <div className='border-[0.98px] border-[#D8D8D8] rounded-[10px] mt-6 min-h-[150px]'>
                     <div className='py-6 px-4 flex justify-between items-center'>
                        <div>
                          <h6 className='text-[#010918] text-sm font-Lota600'>Transaction History</h6>
                        </div>
                        <Link to="/transactions" className='flex gap-2 items-center'>
                            <p className='text-[#2D99FF] text-sm font-Lota400'>See all transactions</p>
                            <img src={chevron_blue_right} alt="chevron right" className='w-2 h-2' />
                        </Link>
                    </div>

                    {/* <div className="overflow-x-auto relative mt-2">
                    <table className="w-full text-left">
                        <thead className="text-[#010918] font-Lota400 bg-white text-sm border-b-[#D8D8D8] border-t-[#D8D8D8] border-b-[0.25px] border-t-[0.25px]">
                        <tr>
                            <th scope="col" className="py-4 px-6">
                            S/N
                            </th>
                            <th scope="col" className="py-4 px-6">
                            Transaction Name
                            </th>
                            <th scope="col" className="py-4 px-6">
                            Amount
                            </th>
                            <th scope="col" className="py-4 px-6">
                            Time
                            </th>
                            <th scope="col" className="py-4 px-6 ">
                            Status
                            </th>
                        </tr>
                    </thead>

                     <tr className="bg-white text-sm font-Lota400 hover:bg-[#cfdffc] hover:bg-opacity-[0.3] border-b-[#D8D8D8] border-t-[#D8D8D8] border-b-[0.25px] border-t-[0.25px]">
                            <td
                              className="py-4 px-6 text-[#010918] whitespace-nowrap"
                            >
                            1
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            DSTV subscription
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                             <span className='text-[10px]'>NGN</span>200.00
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            12:15:52, 11-03-2021
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                              <span className='text-[#FF002A]'>Failed</span> 
                            </td>
                        
                           
                     </tr>

                     <tr className="bg-white text-sm font-Lota400 hover:bg-[#cfdffc] hover:bg-opacity-[0.3] border-b-[#D8D8D8] border-t-[#D8D8D8] border-b-[0.25px] border-t-[0.25px]">
                            <td
                            
                              className="py-4 px-6 text-[#010918] whitespace-nowrap"
                            >
                            2
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            Eko Bill
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                              <span className='text-[10px]'>NGN</span>3,000.00
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            12:15:52, 11-03-2021
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                              <span className='text-[#128933]'>Successful</span> 
                            </td> 
                     </tr>

                     <tr className="bg-white text-sm font-Lota400 hover:bg-[#cfdffc] hover:bg-opacity-[0.3]  border-b-[#D8D8D8] border-t-[#D8D8D8] border-b-[0.25px] border-t-[0.25px]">
                            <td
                              className="py-4 px-6 text-[#010918] whitespace-nowrap"
                            >
                            3
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            GOTV subscription
                            </td>
                           
                            <td className="py-4 px-6 text-[#010918]">
                             <span className='text-[10px]'>NGN</span>5,000.00
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            12:15:52, 11-03-2021
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                              <span className='text-[#FF002A]'>Failed</span> 
                            </td>
                        
                           
                     </tr>

                   </table>
                      </div> */}

                      <div className='mt-8 mb-8'>
                            <div className='flex justify-center'>
                                <img src={emoji1} alt="emoji" />
                            </div>
                            <div className='text-center'>
                                <h6 className='text-[#0041B1] font-Lota400 text-base mt-2'>There is no transaction here</h6>
                                <p className='text-[#111729] text-sm font-Lota400 mt-1'>Make Bills</p>
                            </div>

                      </div>


                </div>

            </div>
        </>
     );
}
 
export default DashboardPage;