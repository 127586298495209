import React,{useEffect} from 'react'
import chevron_right from '../../assets/icons/chevron_right.svg'
import dstv from '../../assets/images/dstv.svg'
import { Link } from 'react-router-dom'
import { fetchInternetServices } from '../../redux/Bills/billActions'
import { useDispatch, useSelector } from 'react-redux'


const InternetPage = () => {

    const dispatch = useDispatch()
    const { services, loader} = useSelector((state) => state.bill);

    useEffect(()=>{
        dispatch(fetchInternetServices())
    },[dispatch])

    return ( 
        <>
             <div className='mt-16 bg-[#FFFFFF] border border-[#D8D8D8] rounded-xl py-8 px-6'>
             <div className='grid grid-cols-3 gap-6'>

                   {loader &&
                        ['2', '1', '4', '5', '3', '8'].map((item) => (
                            <div className="mb-2 bg-[#FFFFFF] shadow-card2 rounded-[10px] py-5 px-4">
                            <div className="animate-pulse w-full">
                                <div className="h-4 bg-gray-200 rounded-md  w-full mb-2"></div>
                                <div className="h-4 bg-gray-200 rounded-md  w-full mb-2"></div>
                                <div className="h-10 bg-gray-200 rounded-md  w-full mb-2"></div>
                                <div className="h-4 bg-gray-200 rounded-md mb-2 mt-5 w-full"></div>
                                <div className="h-4 bg-gray-200 rounded-md  w-full"></div>
                            </div>
                        </div>
                        ))}

                          {/* mapping internet products */}
                          {
                            !loader && services.length ?
                         services.map((val, index)=>(
                            <div key={index} className='shadow-card2 rounded-[10px]'>
                            <div className='flex flex-col justify-center items-center min-h-[220px]'>
                                <img src={dstv} alt="dstv logo" />
                            </div>
                            <div className='flex border-t-[0.45px] border-[#D8D8D8] items-center py-3 px-4 justify-between'>
                                <div>
                                    <p className='text-[#0041B1] text-xs font-Lota400'>Pay your {val.name} Subscription </p>
                                </div>
                                <Link to={`/paybills/internet/${val.product_id}?product=${val.name}`} className='bg-[#F0F0F0] cursor-pointer w-[30px] h-[30px] rounded-full flex justify-center items-center border-[0.54px] border-[#D8D8D8]'>
                                    <img src={chevron_right} alt="chevron right icon" />
                                </Link>
                            </div>
                        </div>
                         ))
                         :
                         ""
                        }
                         
                 </div>
             </div>
        </>
     );
}
 
export default InternetPage;