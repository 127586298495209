import React from 'react';
import close_icon from '../../assets/icons/close_black.svg'
import torch_icon from '../../assets/icons/torch.png'
import { CSSTransition } from 'react-transition-group'
import './modal.css'

const ModalComponent = ({showModal, show, children, title}) => {
    return ( 
        <>
          <CSSTransition
            in={show}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
        >
         <div  onClick={showModal} className="modal 
                    overflow-y-auto overflow-x-hidden bg-[#0A0317]
                    bg-opacity-40 fixed top-0 right-0 left-0 z-50 w-full md:inset-0 md:h-full
                    opacity-0 transition-all duration-[0.3s]" >
             <div  onClick={(e) => e.stopPropagation()} class="relative p-4 w-full max-w-xl h-full md:h-auto mx-auto my-32 modal-content translate-y-[-200px] transition-all duration-[0.3s]">
                 {/* modal content */}
                 <div class="relative bg-white rounded-xl py-5 px-8">

                  {/* modal header */}
                    <div class="flex justify-end">
                        <div className='cursor-pointer' onClick={showModal}>
                           <img src={close_icon} alt="close" />
                        </div>
                    </div>

                    <div className='flex justify-center mt-4'>
                        <img src={torch_icon} alt="torch" />
                    </div>
                    <div className='mt-3 text-center'>
                        <h6 className='text-[#1E1E2F] text-lg font-Lota600'>{title}</h6>
                    </div>

                    {/* modal body */}
                    <div>
                        {children}
                    </div>

                </div>

             </div>
        </div>  
        </CSSTransition> 
        </>
     );
}
 
export default ModalComponent;