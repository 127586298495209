import React,{useState} from 'react'
import { Link } from 'react-router-dom';
import download_icon from '../../assets/icons/download.svg'
// import nodata_icon from '../../assets/icons/Nodata.svg'
import PaginationComponent from '../../components/Pagination/Pagination';


const TransactionsPage = () => {

  let PageSize = 10;
  let count = 80;
  const [currentPage, setCurrentPage] = useState(1);

  const getMoreItemsByOffset = (page) => {
    setCurrentPage(page)
  }

    return ( 
        <>
             <div className='mt-5 md:mt-10 bg-[#FFFFFF] rounded-xl py-6 h-full'>
                    <div className='flex justify-end pr-8'>
                        <div className='flex gap-4'>
                            <div>

                            </div>
                            <div>
                                <button
                                    type="submit"
                                    disabled={false}
                                    className="bg-[#0041B1] 
                                    disabled:bg-[#0041B1] 
                                    disabled:opacity-[0.7]
                                    text-sm
                                    font-Lota400
                                    flex items-center gap-3
                                    rounded-[5px] w-full py-4 px-8 text-[#F0FDF4] 
                                     hover:bg-[#0041B1] hover:opacity-[0.9] transition-all"
                                >   
                                    Download
                                    <img src={download_icon} alt="add icon" className='w-3 h-3' />
                                </button>
                            </div>

                        </div>

                    </div>

                <div className="overflow-x-auto relative mt-6">
                    <table className="w-full text-left">
                        <thead className="text-[#010918] font-Lota400 bg-white text-sm border-b-[#D8D8D8] border-t-[#D8D8D8] border-b-[0.25px] border-t-[0.25px]">
                        <tr>
                            <th scope="col" className="py-4 px-6">
                            S/N
                            </th>
                            <th scope="col" className="py-4 px-6">
                            Transaction Name
                            </th>
                            <th scope="col" className="py-4 px-6">
                            Recipient
                            </th>
                            <th scope="col" className="py-4 px-6">
                            Transaction Type
                            </th>
                            <th scope="col" className="py-4 px-6">
                            Amount
                            </th>
                            <th scope="col" className="py-4 px-6">
                            Time
                            </th>
                            <th scope="col" className="py-4 px-6 ">
                            Status
                            </th>
                        </tr>
                    </thead>

                     <tr className="bg-white text-sm font-Lota400 hover:bg-[#cfdffc] hover:bg-opacity-[0.3] cursor-pointer border-b-[#D8D8D8] border-t-[#D8D8D8] border-b-[0.25px] border-t-[0.25px]">
                            <td
                              className="py-4 px-6 text-[#010918] whitespace-nowrap"
                            >
                            1
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            DSTV subscription
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            DSTV subscription
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            Single
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                             <span className='text-[10px]'>NGN</span>200.00
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            12:15:52, 11-03-2021
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                              <span className='text-[#FF002A]'>Failed</span> 
                            </td>
                        
                           
                     </tr>

                     <tr className="bg-white text-sm font-Lota400 hover:bg-[#cfdffc] hover:bg-opacity-[0.3] cursor-pointer border-b-[#D8D8D8] border-t-[#D8D8D8] border-b-[0.25px] border-t-[0.25px]">
                            <td
                            
                              className="py-4 px-6 text-[#010918] whitespace-nowrap"
                            >
                            2
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            Airtime Recharge
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            70
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                           Bulk
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                              <span className='text-[10px]'>NGN</span>200.00
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            12:15:52, 11-03-2021
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                              <Link to="/transactions/bulk/1334"> View More</Link>
                            </td> 
                     </tr>

                     <tr className="bg-white text-sm font-Lota400 hover:bg-[#cfdffc] hover:bg-opacity-[0.3] cursor-pointer border-b-[#D8D8D8] border-t-[#D8D8D8] border-b-[0.25px] border-t-[0.25px]">
                            <td
                              className="py-4 px-6 text-[#010918] whitespace-nowrap"
                            >
                            3
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            DSTV subscription
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            DSTV subscription
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            Single
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                             <span className='text-[10px]'>NGN</span>200.00
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            12:15:52, 11-03-2021
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                              <span className='text-[#FF002A]'>Failed</span> 
                            </td>
                        
                           
                     </tr>

                     <tr className="bg-white text-sm font-Lota400 hover:bg-[#cfdffc] hover:bg-opacity-[0.3] cursor-pointer border-b-[#D8D8D8] border-t-[#D8D8D8] border-b-[0.25px] border-t-[0.25px]">
                            <td
                              className="py-4 px-6 text-[#010918] whitespace-nowrap"
                            >
                            4
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            DSTV subscription
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            DSTV subscription
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            Single
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                             <span className='text-[10px]'>NGN</span>200.00
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            12:15:52, 11-03-2021
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                              <span className='text-[#128933]'>Successful</span> 
                            </td>
                        
                           
                     </tr>

                     <tr className="bg-white text-sm font-Lota400 hover:bg-[#cfdffc] hover:bg-opacity-[0.3] cursor-pointer border-b-[#D8D8D8] border-t-[#D8D8D8] border-b-[0.25px] border-t-[0.25px]">
                            <td
                              className="py-4 px-6 text-[#010918] whitespace-nowrap"
                            >
                            5
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            DSTV subscription
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            DSTV subscription
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            Single
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                             <span className='text-[10px]'>NGN</span>200.00
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            12:15:52, 11-03-2021
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                              <span className='text-[#FF002A]'>Failed</span> 
                            </td>
                        
                           
                     </tr>

                     <tr className="bg-white text-sm font-Lota400 hover:bg-[#cfdffc] hover:bg-opacity-[0.3] cursor-pointer border-b-[#D8D8D8] border-t-[#D8D8D8] border-b-[0.25px] border-t-[0.25px]">
                            <td
                            
                              className="py-4 px-6 text-[#010918] whitespace-nowrap"
                            >
                            6
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            Airtime Recharge
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            70
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                           Bulk
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                              <span className='text-[10px]'>NGN</span>200.00
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            12:15:52, 11-03-2021
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            <Link to="/transactions/bulk/1323"> View More</Link>
                            </td> 
                     </tr>

                     <tr className="bg-white mt-3 text-sm font-Lota400 hover:bg-[#cfdffc] hover:bg-opacity-[0.3] cursor-pointer border-b-[#D8D8D8] border-t-[#D8D8D8] border-b-[0.25px] border-t-[0.25px]">
                            <td
                              className="py-4 px-6 text-[#010918] whitespace-nowrap"
                            >
                            7
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            DSTV subscription
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            DSTV subscription
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            Single
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                             <span className='text-[10px]'>NGN</span>200.00
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                            12:15:52, 11-03-2021
                            </td>
                            <td className="py-4 px-6 text-[#010918]">
                              <span className='text-[#FF002A]'>Failed</span> 
                            </td>
                         
                     </tr>

             
                   </table>
                </div>

                <div className="flex justify-end mt-6 pr-8">
                     <PaginationComponent
                    currentPage={currentPage}
                    totalCount={count}
                    pageSize={PageSize}
                    onPageChange={(page) => getMoreItemsByOffset(page)}
                    />
                </div>
             </div>

             {/* <div className='mt-16 bg-[#FFFFFF] flex flex-col justify-center items-center rounded-xl py-6 h-full'>
                  <div>
                    <img src={nodata_icon} alt="nodata_icon" className='' />
                      <h6 className='text-[#010918] font-Lota400 text-base mt-8 text-center'>Your data is empty!</h6>
                  </div>
             </div> */}
        </>
     );
}
 
export default TransactionsPage;