import React,{useEffect, useState} from 'react'
import logo_full from '../../assets/icons/logo_full.svg'
import logo from '../../assets/icons/logo_white.svg'
import checkbox_white from '../../assets/icons/checkbox_white.svg'
import PinInput from "react-pin-input";
import { useDispatch,useSelector } from 'react-redux';
import { resendVerifyEmail, verifyEmail } from '../../redux/Auth/authActions';
import { Link, useNavigate } from 'react-router-dom';
import { clearIsVerified } from '../../redux/Auth/auth';

const VerifyEmailPage = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {loading,isVerified, useremail} = useSelector((state) => state.auth);

    const [valid, setValid] = useState(false)
    const [code, setCode] = useState("");

    const handleCode = (val) => {
        setCode(val);
        setValid(false)
    };

    const verifyUser = () =>{
        console.log(code)
        if(code !== ""){
            dispatch(verifyEmail({
                code: code
            }))
        }
        else{
            setValid(true)
        }
    }

    useEffect(()=>{
        if(isVerified){
            navigate('/auth/create-pin')
            setTimeout(()=>{
                dispatch(clearIsVerified())
            },1000)
        }
    },[isVerified, dispatch, navigate])

    const resendLink = () =>{
        dispatch(resendVerifyEmail({
            email: useremail ? useremail : ""
        }))
    }

    return (  
        <>
              <div className='px-5 md:px-16 py-6 authbg font-poppins'>
            
            <Link to="/" className=''>
                <img src={logo_full} className="w-[150px]" alt="logo" />
            </Link>

            <div className='grid md:grid-cols-2 items-center gap-8 mt-20'>
                <div className='hidden md:block'>
                    <div className='flex w-fit bg-[#0041B1] rounded-[36px]'>
                        <p className='text-[#DCFCE7] font-medium text-xs py-1 px-3'>Payments</p>   
                    </div>
                    <div className='mt-6'>
                        <h4 className='font-bold text-[#2A3342] md:text-4xl text-3xl max-w-md md:max-w-lg tracking-wide'>
                        Carefully built to allow you carry bill payments seamlessly.
                        </h4>
                    </div>
                    <div className='mt-8'>
                        <p className='text-[#556987] text-sm font-medium'>Lorem ipsum dolor sit amet, consectetur adipisng.</p>
                    </div>

                    <div className='flex gap-3 items-center mt-6 mb-3'>
                            <div className='bg-[#0041B1] flex justify-center items-center w-[20px] h-[20px] rounded-full'>
                            <img src={checkbox_white} className='' alt="checkbox" />
                        </div>
                        <div>
                    <p className='text-[#556987] text-sm font-medium'>Mauris pellentesque congue libero nec</p>
                        </div>
                    </div>

                    <div className='flex gap-3 items-center mb-3'>
                            <div className='bg-[#0041B1] flex justify-center items-center w-[20px] h-[20px] rounded-full'>
                            <img src={checkbox_white} alt="checkbox" />
                        </div>
                        <div>
                    <p className='text-[#556987] text-sm font-medium'>Suspendisse mollis tincidunt</p>
                        </div>
                    </div>

                    <div className='flex gap-3 items-center mb-3'>
                            <div className='bg-[#0041B1] flex justify-center items-center w-[20px] h-[20px] rounded-full'>
                            <img src={checkbox_white} alt="checkbox" />
                        </div>
                        <div>
                    <p className='text-[#556987] text-sm font-medium'>Praesent varius justo vel justo pulvinar </p>
                        </div>
                    </div>

                </div>
                <div className='bg-white shadow-card relative rounded-lg md:rounded-[16px] py-8 px-6 md:px-16 max-w-lg md:max-w-xl'>

                    <div className='absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                        <div className='bg-[#0041B1] rounded-[12px] w-[55px] h-[55px] flex justify-center items-center'>
                            <img src={logo} className="w-[30px] h-[30px]" alt="logo" />
                        </div>
                    </div>
                    

                    <div className='text-center mt-4'>
                        <h4 className='text-[#2A3342] font-Lota600 md:text-2xl text-xl'>Account Auth</h4>
                        <h6 className='text-[#556987] font-Graphix400 mt-4'>We have sent one time password to <br/> your email address</h6>
                    </div>

                    <div className='text-center mt-6'>
                        <p className='text-[#556987] text-base font-Graphix600 mt-4'>1:00</p>
                    </div>

                     <div className='text-center mt-8'>
                         <PinInput
                            length={6}
                            initialValue={code}
                            focus
                            type="custom"
                            onChange={handleCode}
                            inputStyle={{ borderColor: "#D5DAE1", color: '#AAAAAA' }}
                            inputFocusStyle={{ borderColor: "#0041B1" }}
                        />
                         <small className="text-[#FF002A] font-[300] text-[10px] pt-[4px]" >
                                 {valid ? "Code is required!": ""}
                          </small>
                      </div>

                    <div className='text-center mt-8'>
                         <p className='text-[#8896AB] text-sm font-medium'>Didn’t receive an otp? <button
                            onClick={resendLink}
                          disabled={loading} 
                         className='text-[#0A49BA] disabled:opacity-[0.7] font-medium text-sm'>Resend</button> </p>
                    </div>

                    <div className='mt-10'>
                        <button
                        onClick={verifyUser}
                            type="submit"
                            disabled={loading}
                            className="bg-[#0041B1] 
                            disabled:bg-[#0041B1] 
                            disabled:opacity-[0.7]
                            text-sm
                            rounded-lg w-full py-4 text-[#F0FDF4] font-medium hover:bg-[#0041B1] hover:opacity-[0.9] transition-all"
                        >
                            Confirm
                        </button>
                    </div>

               

                </div>
            </div>

         </div>
        </>
    );
}
 
export default VerifyEmailPage;