import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import {apiUrl} from '../config'


const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};


export const fetchTransactionsWithFilter = createAsyncThunk(
  'fetchTransactionsWithFilter',
  async ({limit}, {rejectWithValue, dispatch, getState}) => {
    try {
      const res = await axios.get(
        `${apiUrl}single-airtime/instructionslimit=${limit}`,
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `${getToken()}`
          }, 
        }
      )
      if(res.status === 200){
          return res.data.data
      }
      
    } catch (error) {
      // return custom error message from API if any
      if(error.response.status === 400 || error.response.status  === 403){
        return rejectWithValue(error.response)
      }
      else if(error.response.status === 401){
        dispatch({type: 'auth/Logout'})
      }
      else{
        return rejectWithValue(error.response)
      }
    }
  }
)