import { configureStore } from '@reduxjs/toolkit'
import authReducer from './Auth/auth'
import topupReducer from './TopUp/topup'
import billReducer from './Bills/bills'
import transactionReducer from './Transactions/transactions'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    topup: topupReducer,
    bill: billReducer,
    transaction: transactionReducer
  },
})